import { Component, EventEmitter, Input, Output, DoCheck } from '@angular/core';
import { SettingsService } from 'src/app/services/service.index';

@Component({
  selector: 'app-tabla',
  templateUrl: './tabla.component.html',
})
export class TablaComponent implements DoCheck {
  @Input() TITLE = '';

  @Input() HEADERS = [];
  @Input() HEADERS2 = [];
  @Input() DATA = [];
  @Input() EVENTS = {
    search: 0,
    update: 0,
    delete: 0,
  };
  @Input() itemIdentificador = '';

  theme = {
    name: 'light',
    background: 'bg-light',
    text: 'text-dark',
    btnType1: 'btn-primary',
  };

  // PARA PAGINACIÓN
  @Input() pagination = {
    actual: 1,
    anterior: false,
    siguiente: false,
  };
  // Fin para paginación

  @Output() emitSearch = new EventEmitter<any>();
  @Output() emitUpdate = new EventEmitter<any>();
  @Output() emitDelete = new EventEmitter<any>();

  @Output() emitChangePage = new EventEmitter<any>();

  init = 1;
  nTime1 = 0;

  constructor(public settingsService: SettingsService) {
    this.setTheme();
  }

  ngDoCheck() {
    if (this.DATA) {
      if (this.DATA.length > 0 && this.nTime1 === 0) {
        this.nTime1 = 1;
      }
    }
  }

  setTheme() {
    this.theme = this.settingsService.loadThemeFromStorage();
  }

  emitirSearch(id) {
    this.emitSearch.emit(id);
  }

  emitirUpdate(id) {
    this.emitUpdate.emit(id);
  }

  emitirDelete(id) {
    this.emitDelete.emit(id);
  }

  cambiar_Pagina(page) {
    this.emitChangePage.emit(page);
  }
}
