<!-- ////////////////////////////////////////////////////////////////////////////-->
<div class="wrapper {{theme.background2}} {{theme.text}}">

    <app-sidebar></app-sidebar>

    <!-- Navbar (Header) Starts-->
    <app-header></app-header>
    <!-- Navbar (Header) Ends-->


    <div class="main-panel">
        <router-outlet></router-outlet>


    </div>
</div>
<!-- ////////////////////////////////////////////////////////////////////////////   -->

<app-cargando></app-cargando>
<app-modal-usuario></app-modal-usuario>
<app-modal-habilidad></app-modal-habilidad>
<app-modal-tarjeta-pregunta-respuesta></app-modal-tarjeta-pregunta-respuesta>
<app-modal-tarjeta-memoria></app-modal-tarjeta-memoria>
<app-modal-crear-entrenamiento></app-modal-crear-entrenamiento>
<app-modal-entrenamiento></app-modal-entrenamiento>
<app-modal-entrenamiento-tarjeta-memoria></app-modal-entrenamiento-tarjeta-memoria>