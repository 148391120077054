import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../services/service.index';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styles: [],
})
export class PagesComponent implements OnInit {
  theme = {
    name: 'light',
    background: 'bg-light',
    background2: 'bg-light',
    text: 'text-dark',
    btnType1: 'btn-primary',
  };

  constructor(public settingsService: SettingsService) {
    this.setTheme();
  }

  ngOnInit() {

    this.settingsService.refreshTheme.subscribe((isOpen) => {
      this.setTheme();
    });
  }

  setTheme() {
    this.theme = this.settingsService.loadThemeFromStorage();
  }
}
