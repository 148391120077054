import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  SettingsService,
  UsuarioService,
} from 'src/app/services/service.index';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  ROL;
  email;

  activeSidebar1 = 0;

  theme = {
    name: 'light',
    background: 'bg-light',
    text: 'text-dark',
    btnType1: 'btn-primary',
  };

  logoUrl = 'assets/img/logo.png';
  constructor(
    public userService: UsuarioService,
    public router: Router,
    public settingsService: SettingsService
  ) {
    this.theme = this.settingsService.loadThemeFromStorage();
  }

  ngOnInit() {
    this.userService
      .obtenerDataToken(this.userService.token)
      .subscribe((data) => {
        this.ROL = data.usuario.rol;
      });

    this.settingsService.refreshTheme.subscribe((isOpen) => {
      this.theme = this.settingsService.loadThemeFromStorage();
    });
  }

  toggleActiveSidebar() {
    if (this.activeSidebar1 === 1) {
      document.getElementsByClassName('wrapper')[0].classList.remove('nav-collapsed');
      document.getElementsByClassName('wrapper')[0].classList.remove('menu-collapsed');
      this.activeSidebar1 = 0;
      return;
    }
    if (this.activeSidebar1 === 0) {
      document.getElementsByClassName('wrapper')[0].classList.add('nav-collapsed');
      document.getElementsByClassName('wrapper')[0].classList.add('menu-collapsed');
      this.activeSidebar1 = 1;
      return;
    }
  }
}
