import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImagesService {
  constructor(public http: HttpClient) { }

  getByUserId(data) {
    const url = URL_SERVICIOS + 'image/getByUserId?token=' + data.token;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  uploadImage(data) {
    const url = URL_SERVICIOS + 'image/uploadImage';

    return this.http
      .post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  removeImageById(data) {
    const url = URL_SERVICIOS + 'image/removeImageById';

    return this.http
      .post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }
}
