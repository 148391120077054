export class HabilidadModel {
  constructor(
    public nombre: string,
    public formatoEntrenamiento: number,
    public id?: number,
    public richDetail?: string,
    public token?: string,
    public state?: number
  ) {}
}
