import { Component, DoCheck } from '@angular/core';
import {
  SettingsService,
  UsuarioService,
} from 'src/app/services/service.index';
import { ModalUsuarioService } from './modal-usuario.service';

import swal from 'sweetalert2';

@Component({
  selector: 'app-modal-usuario',
  templateUrl: './modal-usuario.component.html',
})
export class ModalUsuarioComponent implements DoCheck {
  optionCambioPass = 0;

  nTime1 = 0;
  nTime2 = 0;
  USUARIO_DATA = {
    id: '',
    email: '',
    password: '',
    rol: 'COLABORADOR',
    token: '',
  };

  PASSWORD_DATA = {
    token: '',
    idUsuario: '',
    role: '',
    estado: '',
    new_password: '',
    new_password2: '',
  };

  theme = {
    name: 'light',
    background: 'bg-light',
    background2: 'bg-light',
    text: 'text-dark',
    btnType1: 'btn-primary',
  };

  constructor(
    public modalUserService: ModalUsuarioService,
    public userService: UsuarioService,
    public settingsService: SettingsService
  ) {
    this.theme = this.settingsService.loadThemeFromStorage();
  }

  ngDoCheck() {
    if (this.modalUserService.oculto === '' && this.nTime1 === 0) {
      this.nTime1 = 1;
    }

    if (this.modalUserService.opcion === 'updatePassword' && this.nTime2 === 0) {
      this.nTime2 = 1;
      this.PASSWORD_DATA.estado = this.modalUserService.estado;
      this.PASSWORD_DATA.role = this.modalUserService.role;
    }
  }

  cerrarModal() {
    this.nTime1 = 0;
    this.nTime2 = 0;

    this.USUARIO_DATA = {
      id: '',
      email: '',
      password: '',
      rol: 'COLABORADOR',
      token: '',
    };

    this.modalUserService.ocultarModal();
  }

  changeOption() {
    if (this.optionCambioPass === 0) {
      this.optionCambioPass = 1;
    } else {
      this.optionCambioPass = 0;
      this.PASSWORD_DATA.new_password = '';
      this.PASSWORD_DATA.new_password2 = '';
    }
  }

  updatePassword() {
    if (this.optionCambioPass === 1) {
      if (
        this.PASSWORD_DATA.new_password === '' ||
        this.PASSWORD_DATA.new_password2 === ''
      ) {
        swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Ingrese Contraseña.',
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }

      if (this.PASSWORD_DATA.new_password !== this.PASSWORD_DATA.new_password2) {
        swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Las contraseñas deben ser iguales para la validación.',
          showConfirmButton: false,
          timer: 1500,
        });

        return;
      }
    }

    this.PASSWORD_DATA.token = this.userService.token;
    this.PASSWORD_DATA.idUsuario = this.modalUserService.idUsuario;

    document.getElementById('btn_updatePassword').style.display = 'none';

    this.userService
      .updatePassword(this.PASSWORD_DATA)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.userService.__refreshUsuarios();
          this.cerrarModal();
          return;
        }
        document.getElementById('btn_updatePassword').style.display = 'block';
      });
  }

  agregarUsuario() {
    if (this.USUARIO_DATA.email === '') {
      swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Ingrese e-mail del usuario.',
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }

    if (this.USUARIO_DATA.password === '') {
      swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Ingrese contraseña del usuario.',
        showConfirmButton: false,
        timer: 2500,
      });

      return;
    }

    document.getElementById('btn_add_user').style.display = 'none';

    this.USUARIO_DATA.token = this.userService.token;

    this.userService
      .crearUsuario(this.USUARIO_DATA)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Usuario Creado',
            showConfirmButton: false,
            timer: 4500,
          });
          this.userService.__refreshUsuarios();
          this.cerrarModal();
        } else {
          document.getElementById('btn_add_user').style.display = 'block';
          swal.fire({
            position: 'top-end',
            icon: 'error',
            title: '' + resp2.msn,
            showConfirmButton: false,
            timer: 4500,
          });
        }
      });
  }
}
