import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UsuarioService } from '../usuario/usuario.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuardGuard2Guard implements CanActivate {
  constructor(public userService: UsuarioService, public router: Router) {}

  canActivate() {
    return this.userService.estaLogueado().map((data) => {
      if (data === true) {
        this.router.navigate(['/dashboard']);
        return false;
      } else {
        return true;
      }
    });
  }
}
