<div *ngIf="modalEntrenamientoService.oculto!=='oculto'" class="fondo-negro animated fadeIn">

  <div class="modal" style="display:block;" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content {{theme.background2}} {{theme.text}}" style="overflow: auto;height: 590px;">
        <div class="modal-header">
          <h2 class="modal-title">Entrenamiento - Pregunta respuesta</h2>

          <button (click)="cerrarModal()" type="button" class="close text-danger font-large-4 pt-0 pb-0"
            aria-label="Close">
            <span aria-hidden="true" class="font-large-3">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <section id="icon-tabs">
            <div class="row">
              <div class="col-12">
                <h4 class="text-bold-500">Fecha de entrenamiento: {{fechaEntrenamiento}}</h4>
              </div>
              <div class="col-12">
                <div class="card" style="box-shadow: unset;">
                  <div class="card-content">
                    <div class="card-body modalEntrenamientoSpecial2 {{theme.background}} {{theme.text}}">
                      <form class="icons-tab-steps wizard clearfix" role="application">
                        <div class="steps clearfix">
                          <ul role="tablist">
                            <li role="tab" [ngClass]="{'current': pasoActual === 1, 'done': pasoActual !== 1}"
                              aria-disabled="false" aria-selected="true">
                              <a class="{{theme.textBorder}} font-medium-3">
                                <span class="step {{theme.textBorder}} font-medium-3">1</span> Paso inicio 1
                              </a>
                            </li>

                            <li role="tab" class="current" aria-disabled="false" aria-selected="true">
                              <a class="{{theme.textBorder}} font-medium-3">
                                <span class="step {{theme.textBorder}} font-medium-3">{{pasoActual}}</span> Paso actual
                                {{pasoActual}}
                              </a>
                            </li>

                            <li role="tab"
                              [ngClass]="{'current': pasoActual === pasoFin, 'done': pasoActual !== pasoFin}"
                              aria-disabled="false" aria-selected="true">
                              <a class="{{theme.textBorder}} font-medium-3">
                                <span class="step {{theme.textBorder}} font-medium-3">{{pasoFin}}</span> Paso Final
                                {{pasoFin}}
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="content clearfix">
                          <div [style.display]="displayTags===1 ? 'block' : 'none'">
                            <ng-container *ngFor="let TD of TARJETAS_DATA; index as i">
                              <!-- [ngClass]="{'d-block': pasoActual === i+1, 'd-none': pasoActual != i+1}" -->
                              <fieldset *ngIf="pasoActual === i+1" role="tabpanel" class="body current p-0"
                                aria-hidden="false">

                                <input value="{{TD.idTarjeta}}" autocomplete="none" type="number"
                                  class="form-control d-none" id="idTarjetaNow{{i+1}}">

                                <div *ngIf="vista==='PREGUNTA'"
                                  class="{{theme.background2}} row box-shadow-2 ml-2 mr-2 mt-3 mb-3 p-2 modalEntrenamientoSpecial1">
                                  <ng-container *ngFor="let texto of TD.TEXTO">
                                    <div *ngIf="texto.ET_modo==='PREGUNTA'"
                                      class="col-md-12 text-center modalEntrenamientoSpecial3">
                                      <app-box-text-html [requestData]="requestDataBoxTextHtml"
                                        [mydataTextHtml]="texto.ET_texto" [mode]="'displayData'">
                                      </app-box-text-html>
                                    </div>
                                  </ng-container>

                                  <ng-container *ngFor="let audio of TD.AUDIO">
                                    <div *ngIf="audio.EA_modo==='PREGUNTA'" class="col-md-12 col-lg-7 m-auto p-2">
                                      <audio controls class="w-85 d-block m-auto">
                                        <source
                                          src="{{ PATH_ROOT }}{{ audio.EA_nombreBackend }}{{ audio.EA_extension }}"
                                          type="audio/mpeg">
                                        Your browser does not support the audio element.
                                      </audio>
                                    </div>
                                  </ng-container>

                                </div>
                                <!-- [hidden]="vista!='RESPUESTA'" -->
                                <div *ngIf="vista==='RESPUESTA'"
                                  class="{{theme.background2}} row box-shadow-2 ml-2 mr-2 mt-3 mb-3 p-2 modalEntrenamientoSpecial1">
                                  <ng-container *ngFor="let texto of TD.TEXTO">
                                    <div *ngIf="texto.ET_modo==='RESPUESTA'"
                                      class="col-md-12 text-center modalEntrenamientoSpecial3">
                                      <app-box-text-html [requestData]="requestDataBoxTextHtml"
                                        [mydataTextHtml]="texto.ET_texto" [mode]="'displayData'">
                                      </app-box-text-html>
                                    </div>
                                  </ng-container>

                                  <ng-container *ngFor="let audio of TD.AUDIO">
                                    <div *ngIf="audio.EA_modo==='RESPUESTA'" class="col-md-12 col-lg-7 m-auto p-2">
                                      <audio controls class="w-85 d-block m-auto">
                                        <source
                                          src="{{ PATH_ROOT }}{{ audio.EA_nombreBackend }}{{ audio.EA_extension }}"
                                          type="audio/mpeg">
                                        Your browser does not support the audio element.
                                      </audio>
                                    </div>
                                  </ng-container>
                                </div>

                                <div class="{{theme.background2}} box-shadow-2 ml-2 mr-2 mt-3 mb-3 p-2 ">
                                  <button (click)="toggleDisplatLastTrained()" type="button"
                                    class="btn btn-outline-info pt-1 m-0">
                                    <i class="fa fa-search"></i></button>
                                  <app-tabla *ngIf="stateToDisplatLastTrained===1" [TITLE]="'Últimos 5 entrenamientos'"
                                    [HEADERS]="['#','Fecha de desarrollo','Dias Espaciado','Acierto']"
                                    [HEADERS2]="['PET_fechaDesarrollo','PET_diasEspaciado','PET_lograsteAcertar']"
                                    [DATA]="TD.ultimos5Entrenamientos" [itemIdentificador]="'PET_id'"></app-tabla>
                                </div>
                              </fieldset>
                            </ng-container>
                          </div>
                          <div [style.display]="displayTags===0 ? 'block' : 'none'" class="content-wrapper"
                            style="width: 100%;height: 140px;">
                            <i style="position: absolute;top: 65%;left: 50%;margin: -35px 0 0 -35px;"
                              class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                          </div>

                          <div [style.display]="displayTags===1 ? 'block' : 'none'" *ngIf="vista==='RESPUESTA'"
                            class="{{theme.background2}} row box-shadow-2 ml-2 mr-2 mt-3 mb-3 p-2">

                            <div class="col-md-9 col-lg-7 col-12 m-auto">
                              <h4 class="text-center text-bold-500">Repetición de la tarjeta</h4>
                            </div>
                            <div class="col-md-9 col-lg-7 col-12 m-auto">
                              <select [(ngModel)]="diasRepeticion" name="diasRepeticion" class="form-control mb-2">
                                <option value="1">1 día</option>
                                <option value="2">2 días</option>
                                <option value="5">5 días</option>
                                <option value="10">10 días</option>
                                <option value="0">Personalizado</option>
                              </select>
                            </div>
                            <div class="col-md-9 col-lg-7 col-12 m-auto">
                              <input *ngIf="+diasRepeticion===0"
                                (change)="checkInput({label:'diasRepeticionPersonalizado',type:'number',max:'30',min:'1'})"
                                [(ngModel)]="diasRepeticionPersonalizado" autocomplete="none" min="0" max="30"
                                type="number" class="form-control" name="diasRepeticionPersonalizado">
                            </div>

                            <div class="col-md-9 col-lg-7 col-12 m-auto">
                              <h4 class="text-center text-bold-500">Lograste acertar?</h4>
                            </div>
                            <div class="col-md-9 col-lg-7 col-12 m-auto">
                              <select [(ngModel)]="lograsteAcertar" name="lograsteAcertar" class="form-control mb-2">
                                <option value="1">SI</option>
                                <option value="0">No</option>
                              </select>
                            </div>

                          </div>

                          <button *ngIf="vista==='PREGUNTA' && displayTags===1" (click)="goTo('RESPUESTA')"
                            type="button" class="btn btn-success d-block m-auto btn-lg">Voltear tarjeta</button>
                          <button *ngIf="vista==='RESPUESTA' && displayTags===1" (click)="goTo('PREGUNTA')"
                            type="button" class="btn btn-success d-block m-auto btn-lg">Voltear tarjeta</button>
                        </div>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
        <div class="modal-footer">
          <!-- <button (click)="regresar()" type="button" class="btn btn-secondary">Regresar</button> -->
          <button *ngIf="pasoActual<pasoFin && vista==='RESPUESTA'" (click)="siguiente()" type="button"
            class="btn btn-primary btn-lg">Siguiente</button>
          <button *ngIf="pasoActual===pasoFin && vista==='RESPUESTA'" (click)="siguiente()" type="button"
            class="btn btn-primary btn-lg">Finalizar</button>
        </div>
      </div>
    </div>
  </div>



</div>