import { Injectable, Output, EventEmitter } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HabilidadesService {
  constructor(public http: HttpClient) { }

  @Output() refreshHabilidades: EventEmitter<boolean> = new EventEmitter();
  __refreshHabilidades() {
    this.refreshHabilidades.emit();
  }

  getHabilidadesForStore(data) {
    const url =
      URL_SERVICIOS +
      'habilidad/getHabilidadesForStore?offset=' +
      data.offset +
      '&limit=' +
      data.limit +
      '&busqueda=' +
      data.busqueda +
      '&token=' +
      data.token;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  buscarHabilidadLive(data) {
    const url =
      URL_SERVICIOS +
      'habilidad/buscarHabilidadLive?offset=' +
      data.offset +
      '&limit=' +
      data.limit +
      '&busqueda=' +
      data.busqueda +
      '&token=' +
      data.token;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  obtenerOptionsFormatoEntrenamiento(data) {
    const url =
      URL_SERVICIOS +
      'habilidad/obtenerOptionsFormatoEntrenamiento?token=' +
      data.token;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  getHabilidadById(data) {
    const url =
      URL_SERVICIOS +
      'habilidad/getHabilidadById?token=' +
      data.token +
      '&idHabilidad=' +
      data.idHabilidad;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  obtenerHabilidadByUsuarioId(data) {
    const url =
      URL_SERVICIOS +
      'habilidad/obtenerHabilidadByUsuarioId?token=' +
      data.token;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  agregarHabilidad(data) {
    const url = URL_SERVICIOS + 'habilidad/agregarHabilidad';

    return this.http
      .post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {

        return of(err);
      });
  }

  updateHabilidad(data) {
    const url = URL_SERVICIOS + 'habilidad/updateHabilidad';

    return this.http
      .post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {

        return of(err);
      });
  }

  removeById(data) {
    const url = URL_SERVICIOS + 'habilidad/removeById';

    return this.http
      .post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }
}
