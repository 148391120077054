import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalCrearEntrenamientoService {
  public oculto = 'oculto';

  ocultarModal() {
    this.oculto = 'oculto';
  }
  mostrarModal() {
    this.oculto = '';
  }
}
