import { Injectable, Output, EventEmitter } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TarjetaService {
  constructor(public http: HttpClient) { }

  @Output() refreshTarjetas: EventEmitter<boolean> = new EventEmitter();

  @Output() refreshTarjetasByHabilidadId: EventEmitter<boolean> =
    new EventEmitter();
  __refreshTarjetas() {
    this.refreshTarjetas.emit();
  }
  __refreshTarjetasByHabilidadId() {
    this.refreshTarjetasByHabilidadId.emit();
  }

  agregarTarjeta(data) {
    const url = URL_SERVICIOS + 'tarjeta/agregarTarjeta';

    return this.http
      .post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  establecerProcesoEntrenamientoTarjeta(data) {
    const url = URL_SERVICIOS + 'tarjeta/establecerProcesoEntrenamientoTarjeta';

    return this.http
      .post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  updateTargetsTitleById(data) {
    const url = URL_SERVICIOS + 'tarjeta/updateTargetsTitleById';

    return this.http
      .post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  actualizarElementoTexto(data) {
    const url = URL_SERVICIOS + 'elemento/actualizarElementoTexto';

    return this.http
      .post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  agregarElementoTexto(data) {
    const url = URL_SERVICIOS + 'elemento/agregarElementoTexto';

    return this.http
      .post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  eliminarElementoTexto(data) {
    const url = URL_SERVICIOS + 'elemento/eliminarElementoTexto';

    return this.http
      .post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  getTarjetasByHabilidadId(data) {
    const url =
      URL_SERVICIOS +
      'tarjeta/getTarjetasByHabilidadId?offset=' +
      data.offset +
      '&limit=' +
      data.limit +
      '&busqueda=' +
      data.busqueda +
      '&token=' +
      data.token +
      '&idHabilidad=' +
      data.idHabilidad;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  getTarjetasByHabilidadId2(data) {
    const url =
      URL_SERVICIOS +
      'tarjeta/getTarjetasByHabilidadId2?offset=' +
      data.offset +
      '&limit=' +
      data.limit +
      '&busqueda=' +
      data.busqueda +
      '&token=' +
      data.token +
      '&idHabilidad=' +
      data.idHabilidad;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  getTarjetaById(data) {
    const url =
      URL_SERVICIOS +
      'tarjeta/getTarjetaById?token=' +
      data.token +
      '&tarjetaId=' +
      data.tarjetaId;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  getElementosTextoByTarjetaId(data) {
    const url =
      URL_SERVICIOS +
      'elemento/getElementosTextoByTarjetaId?token=' +
      data.token +
      '&TarjetaID=' +
      data.TarjetaID;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  getElementosAudioByTarjetaId(data) {
    const url =
      URL_SERVICIOS +
      'elemento/getElementosAudioByTarjetaId?token=' +
      data.token +
      '&TarjetaID=' +
      data.TarjetaID;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  getTarjetasByEntrenamiento(data) {
    const url =
      URL_SERVICIOS +
      'tarjeta/getTarjetasByEntrenamiento?token=' +
      data.token +
      '&entrenamientoId=' +
      data.entrenamientoId;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  getTarjetasForTrainingProcess(data) {
    const url =
      URL_SERVICIOS +
      'tarjeta/getTarjetasForTrainingProcess?token=' +
      data.token +
      '&entrenamientoId=' +
      data.entrenamientoId +
      '&fecha=' +
      data.fecha;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  subirElementoAudio(data) {
    const url = URL_SERVICIOS + 'elemento/subirElementoAudio';

    return this.http
      .post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  eliminarElementoAudio(data) {
    const url = URL_SERVICIOS + 'elemento/eliminarElementoAudio';

    return this.http
      .post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  removeById(data) {
    const url = URL_SERVICIOS + 'tarjeta/removeById';

    return this.http
      .post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }
}
