import { Component, OnInit } from '@angular/core';
import {
  SettingsService,
  UsuarioService,
} from 'src/app/services/service.index';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  displayMenu1 = 0;

  Nombre = 'Aaron';
  display1 = '';

  theme = {
    name: 'light',
    background: 'bg-light',
    background2: 'bg-light',
    text: 'text-dark',
    btnType1: 'btn-primary',
  };

  constructor(
    public userService: UsuarioService,
    public settingsService: SettingsService
  ) {
    this.theme = this.settingsService.loadThemeFromStorage();
  }

  toggleOption1() {
    return true;
  }

  toggle1() {
    if (this.display1 === '') {
      this.display1 = 'show';
      return;
    }
    if (this.display1 === 'show') {
      this.display1 = '';
      return;
    }
  }

  ngOnInit() {
    this.settingsService.refreshTheme.subscribe(() => {
      this.theme = this.settingsService.loadThemeFromStorage();
    });
  }
}
