import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UsuarioService } from '../usuario/usuario.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuardGuard implements CanActivate {
  constructor(public userService: UsuarioService, public router: Router) { }

  canActivate() {
    return this.userService.estaLogueado().map((data) => {
      if (data === true) {
        return true;
      } else {
        this.userService.logout();
        return false;
      }
    });
  }
}
