import { Injectable, Output, EventEmitter } from '@angular/core';
import { Usuario } from 'src/app/models/usuario.model';
import { DetalleUsuario } from 'src/app/models/detalleUsuario.model';

import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import swal from 'sweetalert2';
import { Router } from '@angular/router';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  usuarioData: Usuario;

  DetalleUsuario: DetalleUsuario;
  token: string;
  tokenExp: string;

  constructor(public http: HttpClient, public router: Router) {
    this.cargarStorage();
  }

  @Output() refreshUsuarios: EventEmitter<boolean> = new EventEmitter();
  __refreshUsuarios() {
    this.refreshUsuarios.emit();
  }

  getUsers(dataEnviar) {
    const url = URL_SERVICIOS + 'usuarios/getUsuarios?page=' + dataEnviar.page + '&buscar=' + dataEnviar.buscar +
      '&criterio=' + dataEnviar.criterio + '&token=' + dataEnviar.token;
    return this.http.get(url).map((resp: any) => {
      return resp;
    });
  }

  actualizarUsuario(dataToSend) {
    const url = URL_SERVICIOS + 'usuarios/actualizarUsuario';

    return this.http
      .post(url, dataToSend)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  eliminarUsuario(dataToSend) {
    const url = URL_SERVICIOS + 'usuarios/eliminarUsuario';

    return this.http
      .post(url, dataToSend)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  renuevaToken() {
    const url = URL_SERVICIOS + 'login/renuevaToken/' + this.token;

    return this.http.get(url).map((resp: any) => {

      this.guardarStorage(
        resp[0].Token,
        resp[0].Token_expiracion,
        resp[0].usuario
      );

      return true;
    })
      .catch((err) => {
        this.router.navigate(['/login']);
        swal.fire(
          'No se pudo renovar el token',
          'No fué posible renovar token',
          'error'
        );
        return of(err);
      });
  }

  estaLogueado() {
    let token = this.token;

    if (!token) {
      token = '0';
    }

    const url1 = URL_SERVICIOS + 'login/obtener_dataToken?token=' + token;

    return this.http
      .get(url1)
      .map((resp: any) => {
        return resp.ok;
      })
      .catch((err) => {
        return of(err);
      });
  }

  obtenerDataToken(data) {
    const url1 =
      URL_SERVICIOS + 'login/obtener_dataToken?token=' + data;

    return this.http
      .get(url1)
      .map((resp: any) => {
        return resp.dato;
      })
      .catch((err) => {
        return of(err);
      });
  }

  validatePasswordByTokenLogin(data) {
    const url1 =
      URL_SERVICIOS +
      'login/validatePasswordByTokenLogin?token=' +
      data.token +
      '&password=' +
      data.password;

    return this.http
      .get(url1)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  cargarStorage() {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.tokenExp = localStorage.getItem('tokenExp');
    } else {
      this.token = '';
      this.tokenExp = '';
      this.usuarioData = null;

      this.logout();
    }
  }

  agregarUsuarioFromRegistro(usuario: Usuario) {
    const url = URL_SERVICIOS + 'usuarios/agregarUsuarioFromRegistro';

    return this.http
      .post(url, usuario)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  crearUsuario(usuario: Usuario) {
    const url = URL_SERVICIOS + 'usuarios/agregarUsuario';

    return this.http
      .post(url, usuario)
      .map((resp: any) => {
        if (resp.ok) {
          swal.fire('Usuario Creado', resp.email, 'success');
        } else {
          swal.fire('Se producto un error', '' + resp.mensaje, 'error');
        }

        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  buscarUsuario(usuario) {
    const url =
      URL_SERVICIOS + 'usuarios/obtenerUsuarioPorEmail?email=' + usuario.email;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        if (err) {
          swal.fire('Se produjo un error', 'error al buscar usuario', 'error');
        }
        return of(err);
      });
  }

  sendTokenRecoveryPassToEmail(data) {
    // let url=URL_SERVICIOS+ 'usuarios/cambiarClave/'+email+'/';
    const url =
      URL_SERVICIOS +
      'usuarios/sendTokenRecoveryPassToEmail?email=' +
      data.email;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  changePasswordFromRecovery(data) {
    // let url=URL_SERVICIOS+ 'usuarios/cambiarClave/'+email+'/';
    const url = URL_SERVICIOS + 'usuarios/changePasswordFromRecovery';

    return this.http
      .post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  recuperarClave(email) {
    const url =
      URL_SERVICIOS + 'usuarios/cambiarClave/' + email + '/';

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        if (err) {
          swal.fire('Se produjo un error', 'error al cambiar clave', 'error');
        }
        return of(err);
      });
  }

  cambiarContrasena(modelContrasenas) {
    const url = URL_SERVICIOS + 'usuarios/cambiarContrasena';

    return this.http
      .post(url, modelContrasenas)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  login(usuario, recordar = false) {
    if (recordar) {
      localStorage.setItem('email', usuario.email);
    } else {
      localStorage.removeItem('email');
    }

    const url = URL_SERVICIOS + 'login/';

    return this.http
      .post(url, usuario)
      .map((resp: any) => {
        if (!resp.ok) {
          return resp;
        }

        this.guardarStorage(resp.Token, resp.Token_expiracion, resp.usuario);

        return resp;
      })
      .catch((err) => {
        swal.fire('Error en el login', err.error.mensaje, 'error');
        return of(err);
      });
  }

  guardarStorage(token: string, tokenExp: string, usuario) {
    localStorage.setItem('token', token);
    localStorage.setItem('tokenExp', tokenExp);

    this.token = token;
    this.tokenExp = tokenExp;
    this.usuarioData = usuario;
  }

  logout() {
    this.token = '';
    this.tokenExp = '';
    this.usuarioData = null;

    localStorage.removeItem('token');
    localStorage.removeItem('tokenExp');

    this.router.navigate(['/login']);
  }

  guardarDetalleUsuario(detalleUsuario) {
    const url = URL_SERVICIOS + 'detalleUsuario/guardarDetalleUsuario';

    return this.http
      .post(url, detalleUsuario)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  obtenerDetalleUsuario(data) {
    const url =
      URL_SERVICIOS + 'detalleUsuario/obtenerPorIdUsuario?token=' + data.token;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  actualizarDetalleUsuario(detalleUsuario) {
    const url = URL_SERVICIOS + 'detalleUsuario/actualizarDetalleUsuario';

    return this.http
      .post(url, detalleUsuario)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  getIP() {
    const url = 'https://api.ipify.org?format=json';

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  buscarUsuarioLive(data) {
    const url = URL_SERVICIOS + 'usuarios/obtenerUsuariolive?offset=' + data.offset + '&limit=' + data.limit +
      '&busqueda=' + data.busqueda + '&token=' + data.token;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  updatePassword(usuario) {
    const url = URL_SERVICIOS + 'usuarios/updatePassword';

    return this.http
      .post(url, usuario)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }
}
