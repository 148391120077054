import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';
import { UsuarioService } from './usuario/usuario.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectToService {
  constructor(
    public router: Router,
    public http: HttpClient,
    public userService: UsuarioService
  ) { }

  toModuloHabilidad(data) {
    this.generarTokenData(data).subscribe((respuesta: any) => {
      if (respuesta.ok) {
        this.router.navigate(['/moduloHabilidad/' + respuesta.token_generado]);
      }
    });
  }

  toGestionEntrenamiento(data) {
    this.generarTokenData(data).subscribe((respuesta: any) => {
      if (respuesta.ok) {
        this.router.navigate([
          '/gestionEntrenamiento/' + respuesta.token_generado,
        ]);
      }
    });
  }

  generarTokenData(data) {
    const url = URL_SERVICIOS + 'login/generarTokenData';

    const dataEnviar = {
      data,
      token: this.userService.token,
    };

    return this.http
      .post(url, dataEnviar)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  getDataFromToken(token) {
    const url = URL_SERVICIOS + 'login/obtener_dataToken?token=' + token;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }
}
