import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(public http: HttpClient) {}

  getProcessTrainingByUserId(data) {
    const url =
      URL_SERVICIOS +
      'dashboard/getProcessTrainingByUserId?token=' +
      data.token;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }
}
