import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Rutas
import { APP_ROUTES } from './app-routing.module';

// Modulos
import { PagesModule } from './pages/pages.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Servicios

import { ServiceModule } from './services/service.module';

// Componentes
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './login/register.component';
import { PagesComponent } from './pages/pages.component';
import { SharedModule } from './shared/shared.module';
import { RecuperarClaveComponent } from './login/recuperar-clave/recuperar-clave.component';
import { CargandoComponent } from './componentes/cargando/cargando.component';
import { ModalUsuarioComponent } from './componentes/modal-usuario/modal-usuario.component';
import { ModalTarjetaPreguntaRespuestaComponent } from './componentes/modal-tarjeta-pregunta-respuesta/modal-tarjeta-pregunta-respuesta.component';
import { ModalTarjetaMemoriaComponent } from './componentes/modal-tarjeta-memoria/modal-tarjeta-memoria.component';
import { ModalCrearEntrenamientoComponent } from './componentes/modal-crear-entrenamiento/modal-crear-entrenamiento.component';
import { ModalEntrenamientoComponent } from './componentes/modal-entrenamiento/modal-entrenamiento.component';
import { VistasModuleModule } from './vistas/vistas-module.module';
import { ModalEntrenamientoTarjetaMemoriaComponent } from './componentes/modal-entrenamiento-tarjeta-memoria/modal-entrenamiento-tarjeta-memoria.component';
import { NgxEditorModule } from 'ngx-editor';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ComponentesModule } from './componentes/componentes.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    PagesComponent,
    RecuperarClaveComponent,
    CargandoComponent,
    ModalUsuarioComponent,
    ModalTarjetaPreguntaRespuestaComponent,
    ModalTarjetaMemoriaComponent,
    ModalCrearEntrenamientoComponent,
    ModalEntrenamientoComponent,
    ModalEntrenamientoTarjetaMemoriaComponent,
  ],
  imports: [
    BrowserModule,
    APP_ROUTES,
    VistasModuleModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceModule,
    SharedModule,
    NgxEditorModule,
    ComponentesModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
