import { Injectable, Output, EventEmitter } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

import swal from 'sweetalert2';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EntrenamientoService {
  constructor(public http: HttpClient) { }

  @Output() refreshEntrenamientos: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshEntrenamientoModulo: EventEmitter<boolean> = new EventEmitter();

  __refreshEntrenamientos() {
    this.refreshEntrenamientos.emit();
  }
  __refreshEntrenamientoModulo() {
    this.refreshEntrenamientoModulo.emit();
  }

  agregarEntrenamiento(data) {
    const url = URL_SERVICIOS + 'entrenamiento/agregarEntrenamiento';

    return this.http
      .post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  establecerPuntoProceso(data) {
    const url = URL_SERVICIOS + 'entrenamiento/establecerPuntoProceso';

    return this.http
      .post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  eliminarEntrenamiento(data) {
    const url = URL_SERVICIOS + 'entrenamiento/eliminarEntrenamiento';

    return this.http.post(url, data).map((resp: any) => {
      return resp;
    })
      .catch((err) => {
        return of(err);
      });
  }

  actualizarCantidadTarjetasEntrenamiento(data) {
    const url =
      URL_SERVICIOS + 'entrenamiento/actualizarCantidadTarjetasEntrenamiento';

    return this.http
      .post(url, data)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  getEntrenamientosByIdUsuario(data) {
    const url =
      URL_SERVICIOS +
      'entrenamiento/getEntrenamientosByIdUsuario?offset=' +
      data.offset +
      '&limit=' +
      data.limit +
      '&busqueda=' +
      data.busqueda +
      '&token=' +
      data.token;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  getDataEntrenamientoById(data) {
    const url = URL_SERVICIOS + 'entrenamiento/getDataEntrenamientoById?token=' + data.token +
      '&entrenamientoId=' + data.entrenamientoId;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  getDataEntrenamientoByIdTarjeta(data) {
    const url =
      URL_SERVICIOS +
      'entrenamiento/getDataEntrenamientoByIdTarjeta?token=' +
      data.token +
      '&idHabilidad=' +
      data.idHabilidad;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  getProcesoEntrenamientoByTarjetaId(data) {
    const url =
      URL_SERVICIOS +
      'entrenamiento/getProcesoEntrenamientoByTarjetaId?offset=' +
      data.offset +
      '&limit=' +
      data.limit +
      '&tarjetaId=' +
      data.tarjetaId +
      '&token=' +
      data.token;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  getProcesoEntrenamientoPorFecha(data) {
    const url =
      URL_SERVICIOS +
      'entrenamiento/getProcesoEntrenamientoPorFecha?token=' +
      data.token +
      '&entrenamientoId=' +
      data.entrenamientoId +
      '&fecha=' +
      data.fecha;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }

  getDesarrolloTarjetasByEntrenamiento(data) {
    const url = URL_SERVICIOS + 'entrenamiento/getDesarrolloTarjetasByEntrenamiento?token=' + data.token +
      '&entrenamientoId=' + data.entrenamientoId + '&offset=' + data.offset + '&limit=' + data.limit + '&busqueda=' + data.busqueda;

    return this.http
      .get(url)
      .map((resp: any) => {
        return resp;
      })
      .catch((err) => {
        return of(err);
      });
  }
}
