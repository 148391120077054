ines (98 sloc) 5.97 KB

<div class="fondo-negro animated fadeIn" [ngClass]="modalCrearEntrenamientoService.oculto">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content {{theme.background2}} {{theme.text}}" style="overflow: auto;height: 555px;">
                <div class="modal-header">
                    <h2 class="modal-title">Agregar entrenamiento</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">


                    <section id="icon-tabs">
                        <div class="row">
                            <div class="col-12">
                                <div class="card" style="box-shadow: unset;">
                                    <div class="card-content {{theme.background}} {{theme.text}}">
                                        <div class="card-body">
                                            <form class="icons-tab-steps wizard clearfix" role="application"
                                                id="steps-uid-0">
                                                <div class="steps clearfix">
                                                    <ul role="tablist">
                                                        <li role="tab" class="first "
                                                            [ngClass]="{'current': pasoActual === 1, 'done': pasoActual !== 1}"
                                                            aria-disabled="false" aria-selected="true">
                                                            <a class="text-dark">
                                                                <span class="current-info audible">current step: </span>
                                                                <span class="step">1</span>
                                                                <p class=" {{theme.text}}">Paso 1</p>
                                                            </a>
                                                        </li>
                                                        <!-- <li role="tab" [ngClass]="{'current': pasoActual === 2, 'done': pasoActual != 2}" aria-disabled="false" aria-selected="false">
                                            <a>
                                                  <span class="step">2</span> Paso 2
                                              </a>
                                          </li> -->

                                                    </ul>
                                                </div>
                                                <div class="content clearfix">
                                                    <!-- Step 1 -->
                                                    <h6 id="steps-uid-0-h-0" tabindex="-1" class="title current">Step 1
                                                    </h6>
                                                    <fieldset
                                                        [ngClass]="{'d-block': pasoActual === 1, 'd-none': pasoActual !== 1}"
                                                        id="steps-uid-0-p-0" role="tabpanel"
                                                        aria-labelledby="steps-uid-0-h-0" class="body current"
                                                        aria-hidden="false" style="">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <h3 class="modal-title text-bold-600">Datos del
                                                                    entrenamiento</h3>
                                                            </div>
                                                            <div class="col-md-12 mt-2">
                                                                <label
                                                                    class="h5 mt-1 text-bold-500 {{theme.text}}">Habilidad</label>
                                                                <select [(ngModel)]="ENTRENAMIENTO_DATA.idHabilidad"
                                                                    name="idHabilidad"
                                                                    class="form-control font-medium-3 {{theme.background}} {{theme.text}}">
                                                                    <option *ngFor="let h of HABILIDADES_DATA"
                                                                        value="{{h.HA_id}}">{{h.HA_nombre}} -
                                                                        {{h.FORM_ENTRE_nombre}}</option>
                                                                </select>
                                                                <p *ngIf="ENTRENAMIENTO_DATA.idHabilidad===0"
                                                                    class="text-danger">Selecciona una
                                                                    habilidad</p>
                                                            </div>

                                                            <div class="col-md-12 mt-2">
                                                                <label
                                                                    class="h5 mt-1 text-bold-500 {{theme.text}}">Nivel
                                                                    de conocimiento</label>
                                                                <select
                                                                    [(ngModel)]="ENTRENAMIENTO_DATA.nivelConocimiento"
                                                                    name="users-list-role"
                                                                    class="form-control font-medium-3 {{theme.background}} {{theme.text}}">
                                                                    <option value="1">Ninguno</option>
                                                                    <option value="2">Poco</option>
                                                                    <option value="3">Intermedio</option>
                                                                    <option value="4">Avanzado</option>
                                                                    <option value="5">Experto</option>
                                                                </select>
                                                            </div>

                                                        </div>

                                                    </fieldset>
                                                    <!-- Step 2 -->
                                                    <!-- <h6 id="steps-uid-0-h-1" tabindex="-1" class="title">Step 2</h6>
                                <fieldset [ngClass]="{'d-block': pasoActual === 2, 'd-none': pasoActual != 2}" id="steps-uid-0-p-1" role="tabpanel" aria-labelledby="steps-uid-0-h-1" class="body" aria-hidden="true" style="display: none;">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label class="h3 mt-5">Visión del proyecto</label>
                                        <textarea [(ngModel)]="visionProyecto" rows="6" class="form-control" name="visionProyecto"></textarea>
                                      </div>
                                      
                                    </div>
                                    
                                  </div>
                                </fieldset> -->

                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
                <div class="modal-footer">
                    <button *ngIf="pasoActual===pasoFin && ENTRENAMIENTO_DATA.idHabilidad!==0" (click)="crear()"
                        type="button" class="btn btn-primary btn-lg">Crear</button>
                </div>
            </div>
        </div>
    </div>



</div>