import { Component, DoCheck, ViewEncapsulation } from '@angular/core';
import {
  EntrenamientoService,
  RedirectToService,
  SettingsService,
  TarjetaService,
  UsuarioService,
} from 'src/app/services/service.index';
import { CargandoService } from '../cargando/cargando.service';
import { ModalEntrenamientoService } from './modal-entrenamiento.service';

import { URL_SERVICIOS } from 'src/app/config/config';

@Component({
  selector: 'app-modal-entrenamiento',
  templateUrl: './modal-entrenamiento.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ModalEntrenamientoComponent implements DoCheck {
  displayTags = 0;
  requestDataBoxTextHtml = 0;
  stateToDisplatLastTrained = 0;

  vista = 'PREGUNTA';

  fechaEntrenamiento = this.getToday();

  theme = {
    name: 'light',
    background: 'bg-light',
    background2: 'bg-light',
    text: 'text-dark',
    text2: '',
    textBorder: '',
    btnType1: 'btn-primary',
  };

  nTime1 = 0;

  TARJETAS_DATA = [];

  TARJETAS_CONFIG = {
    cantidadTarjetaEntrenamiento: 0,
  };

  pasoActual = 1;
  pasoFin = 2;

  lograsteAcertar = 1;
  diasRepeticion = 1;
  diasRepeticionPersonalizado = 1;

  PATH_ROOT = URL_SERVICIOS + 'upload/';

  constructor(
    public modalEntrenamientoService: ModalEntrenamientoService,
    public userService: UsuarioService,
    public loadingService: CargandoService,
    private redirectToRouteService: RedirectToService,
    public entrenamientoService: EntrenamientoService,
    private targetService: TarjetaService,
    public settingsService: SettingsService
  ) {
    this.theme = this.settingsService.loadThemeFromStorage();
  }

  cerrarModal() {
    this.displayTags = 0;
    this.nTime1 = 0;
    this.pasoActual = 1;
    this.pasoFin = 2;

    this.lograsteAcertar = 1;

    this.diasRepeticion = 1;
    this.diasRepeticionPersonalizado = 1;

    this.vista = 'PREGUNTA';
    this.TARJETAS_DATA = [];
    this.TARJETAS_CONFIG = {
      cantidadTarjetaEntrenamiento: 0,
    };

    this.entrenamientoService.__refreshEntrenamientoModulo();
    this.modalEntrenamientoService.ocultarModal();
  }

  ngDoCheck() {
    if (
      this.modalEntrenamientoService.idEntrenamiento !== 0 &&
      this.modalEntrenamientoService.oculto === '' &&
      this.nTime1 === 0
    ) {
      this.cargarDatos();
      this.nTime1 = 1;
    }
  }

  cargarDatos() {
    this.loadingService.mostrarAnimacion();
    this.getDataEntrenamientoById()
      .then(() => {
        this.getTarjetasForTrainingProcess()
          .then(() => {
            this.getProcesoEntrenamientoPorFecha()
              .then(() => {
                this.setDisable()
                  .then(() => {
                    this.loadingService.ocultarAnimacion();
                  });
              });
          });
      });
  }

  goTo(toWhere) {
    this.displayTags = 0;
    this.vista = toWhere;
    this.setDisable()
      .then(() => {
        this.displayTags = 1;
      });
  }

  setDisable(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let index;
        const list = document.getElementsByClassName('ProseMirror');
        for (index = 0; index < list.length; ++index) {
          list[index].setAttribute('contenteditable', 'false');
        }
        resolve(true);
      }, 1000);
    });
  }

  getTarjetasForTrainingProcess(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const today2 = this.getToday();

      const dataEnviar = {
        entrenamientoId: this.modalEntrenamientoService.idEntrenamiento,
        token: this.userService.token,
        fecha: today2,
      };
      this.displayTags = 0;
      this.targetService
        .getTarjetasForTrainingProcess(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.TARJETAS_DATA = Object.values(respuesta.data);
            this.TARJETAS_DATA = this.TARJETAS_DATA.slice(
              0,
              this.TARJETAS_CONFIG.cantidadTarjetaEntrenamiento
            );
            this.pasoFin = this.TARJETAS_DATA.length;

            this.displayTags = 1;
            resolve(true);
            return;
          }

          resolve(true);
        });
    });
  }

  toggleDisplatLastTrained() {
    if (this.stateToDisplatLastTrained === 0) {
      this.stateToDisplatLastTrained = 1;
    } else {
      this.stateToDisplatLastTrained = 0;
    }
  }

  getProcesoEntrenamientoPorFecha(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const today2 = this.getToday();

      const dataEnviar = {
        entrenamientoId: this.modalEntrenamientoService.idEntrenamiento,
        token: this.userService.token,
        fecha: today2,
      };

      this.entrenamientoService.getProcesoEntrenamientoPorFecha(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok && respuesta.data[0]) {
            if (this.pasoFin >= respuesta.data[0].pasoMaxDesarrollado + 1) {
              this.pasoActual = respuesta.data[0].pasoMaxDesarrollado + 1;
            } else {
              this.cerrarModal();
            }

            resolve(true);
            return;
          }
          resolve(true);
        });
    });
  }

  regresar() {
    if (this.pasoActual > 1) {
      this.vista = 'PREGUNTA';
      this.pasoActual--;
    }
  }

  getToday() {
    const today = new Date();
    const dd = today.getDate();

    const mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();

    const today2 = yyyy + '-' + mm + '-' + dd;

    return today2;
  }

  siguiente() {
    const today2 = this.getToday();

    if (this.pasoActual <= this.pasoFin) {
      this.vista = 'PREGUNTA';
      const dataEnviar = {
        token: this.userService.token,
        entrenamientoId: this.modalEntrenamientoService.idEntrenamiento,
        pasoActual: this.pasoActual,
        fecha: today2,
      };

      const idTarjetaNow = ((
        document.getElementById('idTarjetaNow' + this.pasoActual)
      ) as HTMLInputElement).value;

      const dataEnviar2 = {
        token: this.userService.token,
        entrenamientoId: this.modalEntrenamientoService.idEntrenamiento,
        idTarjetaNow,
        diasRepeticion:
          this.diasRepeticion > 0
            ? this.diasRepeticion
            : this.diasRepeticionPersonalizado,
        fecha: today2,
        lograsteAcertar: this.lograsteAcertar,
      };
      this.displayTags = 0;

      this.entrenamientoService.establecerPuntoProceso(dataEnviar)
        .subscribe((resp2: any) => {
          if (resp2.ok) {
            this.targetService
              .establecerProcesoEntrenamientoTarjeta(dataEnviar2)
              .subscribe((resp3: any) => {
                if (resp3.ok) {
                  if (this.pasoActual === this.pasoFin) {
                    this.cerrarModal();
                    return;
                  }
                  this.pasoActual++;
                  this.setDisable()
                    .then(() => {
                      this.displayTags = 1;
                    });
                }
              });
          }
        });
    }
  }

  getDataEntrenamientoById(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dataEnviar = {
        token: this.userService.token,
        entrenamientoId: this.modalEntrenamientoService.idEntrenamiento,
      };

      this.entrenamientoService.getDataEntrenamientoById(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.TARJETAS_CONFIG.cantidadTarjetaEntrenamiento =
              respuesta.data[0].E_cantidadCartasEntrenar;
            resolve(true);
            return;
          } else {
            resolve(false);
          }
        });
    });
  }

  checkInput(data) {
    if (data.type === 'number') {
      if (!this['' + data.label]) {
        this['' + data.label] = 0;
      }

      if (this['' + data.label] < data.min) {
        this['' + data.label] = data.min;
      }

      if (this['' + data.label] > data.max) {
        this['' + data.label] = data.max;
      }
    }
  }
}
