import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalHabilidadService {
  public oculto = 'oculto';
  public idHabilidad = 0;
  public richDetail: Record<string, any>;
  public nombreHabilidad = '';
  public state = 99;

  ocultarModal() {
    this.oculto = 'oculto';
    this.idHabilidad = 0;
  }
  mostrarModal(
    idHabilidad = 0,
    richDetail = null,
    nombreHabilidad = '',
    state = 99
  ) {
    this.oculto = '';
    this.idHabilidad = idHabilidad;
    this.richDetail = richDetail;
    this.nombreHabilidad = nombreHabilidad;
    this.state = state;
  }
}
