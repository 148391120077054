<div *ngIf="modalTarjetaMemoriaService.oculto!=='oculto'" class="fondo-negro animated fadeIn">

    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content {{theme.background2}} {{theme.text}}" style="overflow: auto;height: 660px;">
                <div class="modal-header">
                    <h2 class="modal-title">Tarjeta - Memoria</h2>

                    <button (click)="cerrarModal()" type="button" class="close text-danger font-large-4"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" align="center">

                    <div class="card-body">
                        <div class="form-group row">
                            <label class="label-control text-bold-500 font-large-1">Título</label>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-9 col-lg-7 col-12 m-auto">
                                <div *ngIf="modalTarjetaMemoriaService.onlySee===0" class="input-group">
                                    <input [(ngModel)]="title" autocomplete="none" type="text"
                                        class="form-control {{theme.background}} {{theme.text}}">
                                    <div class="input-group-append">
                                        <div *ngIf="btnUpdateTitle.cargando===0" (click)="updateTargetsTitleById()"
                                            class="input-group-text cursor-pointer">
                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                        </div>
                                        <div *ngIf="btnUpdateTitle.cargando===1"
                                            class="input-group-text cursor-pointer">
                                            <i class="fa fa-spinner fa-spin"></i>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="modalTarjetaMemoriaService.onlySee===1" class="input-group">
                                    <input [(ngModel)]="title" disabled="disabled" type="text"
                                        class="form-control {{theme.background}} {{theme.text}}">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="form-group row">
                            <label class="label-control text-bold-500 font-large-1">Contexto tarjeta</label>
                        </div>

                        <div class="accordion" id="PRE_accordionTexto">
                            <div class="card box-shadow-2">
                                <button (click)="boxToggle.text=!boxToggle.text"
                                    class="btn btn-link btn-block text-left m-0 {{theme.background}} {{theme.text}}"
                                    style="background: #F5F5F5;" type="button">
                                    <h4 class="text-bold-600 m-3">Texto</h4>
                                </button>

                                <div [class.collapse]="!boxToggle.text" class="{{theme.background2}} {{theme.text}}">
                                    <div class="card-body pt-1">
                                        <div *ngIf="displayBoxHtmlText===0" style="width: 100%;height: 150px;">
                                            <i class="fa-li fa fa-spinner fa-spin"
                                                style="position: relative;top: 0;bottom: 0;left: 0;right: 0;font-size: 40px;z-index: 99;"></i>
                                        </div>

                                        <ng-container *ngIf="ELEMENTOS_TEXTO">
                                            <ng-container *ngIf="ELEMENTOS_TEXTO.length===0">
                                                <app-manage-card
                                                    *ngIf="modalTarjetaMemoriaService.onlySee===0 && displayBoxHtmlText===1"
                                                    [viewExecution]="SETTINGS_MANAGE_CARD.viewExecution"
                                                    [type]="'MEMORIA'" [idElemento]="DATA_UPDATE.idElemento"
                                                    [text]="DATA_UPDATE.elementoTexto"
                                                    (emitAdd)="agregarElementoTexto($event)"
                                                    (emitUpdate)="updateElementoTexto($event)"
                                                    (emitCancelUpdate)="clear2()">
                                                </app-manage-card>
                                            </ng-container>
                                        </ng-container>

                                        <ng-container *ngIf="ELEMENTOS_TEXTO">
                                            <ng-container
                                                *ngIf="ELEMENTOS_TEXTO.length>0 && SETTINGS_MANAGE_CARD.viewExecution==='update'">
                                                <app-manage-card
                                                    *ngIf="modalTarjetaMemoriaService.onlySee===0 && displayBoxHtmlText===1"
                                                    [viewExecution]="SETTINGS_MANAGE_CARD.viewExecution"
                                                    [type]="'MEMORIA'" [idElemento]="DATA_UPDATE.idElemento"
                                                    [text]="DATA_UPDATE.elementoTexto" [btnAdd]="0"
                                                    (emitUpdate)="updateElementoTexto($event)"
                                                    (emitCancelUpdate)="clear2()">
                                                </app-manage-card>
                                            </ng-container>
                                        </ng-container>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="w-100 mt-2 mb-2 border"
                                                    *ngFor="let ET of ELEMENTOS_TEXTO; index as i">
                                                    <button
                                                        *ngIf="modalTarjetaMemoriaService.onlySee===0 && SETTINGS_MANAGE_CARD.viewExecution==='add'"
                                                        type="button" class="btn btn-danger m-0"
                                                        (click)="eliminarElementoTexto(ET.ET_id)"
                                                        style="position: relative;float: right;right: -16px;top: -15px;">
                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                    </button>
                                                    <button
                                                        *ngIf="modalTarjetaMemoriaService.onlySee===0 && SETTINGS_MANAGE_CARD.viewExecution==='add'"
                                                        type="reset" class="btn btn-primary m-0"
                                                        (click)="actualizarElementoTexto(ET)"
                                                        style="position: relative;float: right;right: -15px;top: -15px;">
                                                        <i aria-hidden="true" class="fa fa-pencil-square-o"></i>
                                                    </button>
                                                    <div id="boxPregunta_{{i}}"
                                                        style="min-height: 90px;clear: both;text-align: left;"
                                                        class="w-100">
                                                    </div>
                                                </div>

                                                <!-- <app-tabla *ngIf="modalTarjetaMemoriaService.onlySee==0"
                                                    [HEADERS]="['#','Texto','Posición','Acciones']"
                                                    [HEADERS2]="['ET_texto','ET_position']" [DATA]="ELEMENTOS_TEXTO"
                                                    [itemIdentificador]="'ET_id'" [EVENTS]="{'delete':1,'update':1}"
                                                    (emitUpdate)="actualizarElementoTexto($event)"
                                                    (emitDelete)="eliminarElementoTexto($event)"></app-tabla>

                                                <app-tabla *ngIf="modalTarjetaMemoriaService.onlySee==1"
                                                    [HEADERS]="['#','Texto','Posición']"
                                                    [HEADERS2]="['ET_texto','ET_position']" [DATA]="ELEMENTOS_TEXTO"
                                                    [itemIdentificador]="'ET_id'"></app-tabla> -->

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="accordion" id="PRE_accordionAudio">
                            <div class="card box-shadow-2">
                                <button (click)="boxToggle.sound=!boxToggle.sound"
                                    class="btn btn-link btn-block text-left m-0 {{theme.background}} {{theme.text}}"
                                    style="background: #F5F5F5;" type="button">
                                    <h4 class="text-bold-600 m-3">Audio</h4>
                                </button>

                                <div [class.collapse]="!boxToggle.sound" class="{{theme.background2}} {{theme.text}}">
                                    <div class="card-body">

                                        <div *ngIf="modalTarjetaMemoriaService.onlySee===0" class="row mb-3">
                                            <div class="col-md-12">
                                                <button type="button" class="w-100"><span style="
                                            position: absolute;
                                            transform: translate(-50%, -50%);
                                            top: 50%;
                                            left: 50%;
                                        ">Subir audio</span>
                                                    <input class="w-100" style="opacity: 0;" id="PRE_file-upload_AUDIO"
                                                        type="file" name="file"
                                                        (change)="onSelectAudio($event,'MEMORIA')" accept=".mp3">
                                                </button>

                                            </div>
                                        </div>

                                        <div class="row">
                                            <div *ngFor="let audio of ELEMENTOS_AUDIO" class="col-md-6">
                                                <audio controls class="w-85 float-left">
                                                    <source
                                                        src="{{ PATH_ROOT }}{{ audio.EA_nombreBackend }}{{ audio.EA_extension }}"
                                                        type="audio/mpeg">
                                                    Your browser does not support the audio element.
                                                </audio>

                                                <button *ngIf="modalTarjetaMemoriaService.onlySee===0" type="button"
                                                    (click)="eliminarElementoAudio(audio)"
                                                    class="btn btn-danger mb-0 float-right"
                                                    style="position: absolute;right: 18px;bottom: 0px;">
                                                    <i class="ft-trash"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>

                    <div *ngIf="modalTarjetaMemoriaService.onlySee===0" class="card-body">
                        <div class="form-group row">
                            <label class="label-control text-bold-500 font-large-1">Archivos</label>
                        </div>

                        <div class="accordion" id="RESP_accordionImagen">
                            <div class="card box-shadow-2">
                                <button (click)="boxToggle.images=!boxToggle.images"
                                    class="btn btn-link btn-block text-left m-0 {{theme.background}} {{theme.text}}"
                                    type="button">
                                    <h4 class="text-bold-600 m-3">Imagen</h4>
                                </button>

                                <div [class.collapse]="!boxToggle.images" class="{{theme.background2}} {{theme.text}}">
                                    <div class="card-body">

                                        <div *ngIf="modalTarjetaMemoriaService.onlySee===0" class="row mb-3">
                                            <div class="col-md-12">
                                                <button type="button" class="w-100" style="border: 3px dotted;">
                                                    <span style="position: absolute;transform: translate(-50%, -50%);
                                                        top: 50%;left: 50%;">
                                                        Subir imagen
                                                    </span>
                                                    <input class="w-100" style="opacity: 0;"
                                                        id="RESP_file-upload_IMAGEN" type="file" name="file"
                                                        (change)="onSelect($event)"
                                                        accept="image/png, image/jpeg, image/jpg">
                                                </button>

                                            </div>
                                        </div>

                                        <div class="row">
                                            <input [(ngModel)]="urlImgRequested" readonly="readonly" type="text"
                                                class="border-primary form-control" name="urlImgRequested"
                                                placeholder="Url Imagen">
                                        </div>
                                        <div class="row pt-3">

                                            <div *ngFor="let imagen of ELEMENTOS_IMAGEN" class="col-md-3">
                                                <!-- (click)="_VistaImagenService.mostrarModal(''+PATH_ROOT+imagen.EI_nombreBackend+imagen.EI_extension+'')" -->
                                                <img (click)="getUrlImage(PATH_ROOT+''+imagen.EI_nombreBackend+''+imagen.EI_extension)"
                                                    class="border cursor-pointer"
                                                    src="{{ PATH_ROOT }}{{ imagen.EI_nombreBackend }}{{ imagen.EI_extension }}"
                                                    style="width: 100%;">

                                                <button *ngIf="modalTarjetaMemoriaService.onlySee===0" type="button"
                                                    (click)="removeImageById(imagen)"
                                                    class="btn btn-danger mb-0 float-right"
                                                    style="position: absolute;right: 18px;bottom: 0px;">
                                                    <i class="ft-trash"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>


                </div>
                <div class="modal-footer">
                    <button (click)="cerrarModal()" type="button" class="btn btn-danger">cerrar</button>
                </div>
            </div>
        </div>
    </div>
</div>