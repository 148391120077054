import { Component, DoCheck, ViewChild, ElementRef } from '@angular/core';
import { CargandoService } from '../cargando/cargando.service';
import { ModalTarjetaPreguntaRespuestaService } from './modal-tarjeta-pregunta-respuesta.service';

import {
  HabilidadesService,
  ImagesService,
  SettingsService,
  TarjetaService,
  UsuarioService,
} from 'src/app/services/service.index';
import { URL_SERVICIOS } from 'src/app/config/config';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-tarjeta-pregunta-respuesta',
  templateUrl: './modal-tarjeta-pregunta-respuesta.component.html',
})
export class ModalTarjetaPreguntaRespuestaComponent implements DoCheck {
  title = '';

  btnUpdateTitle = {
    cargando: 0,
  };

  urlImgRequested = '';

  ELEMENTOS_TEXTO = [];
  ELEMENTOS_TEXTO_RESPUESTA = [];

  ELEMENTOS_IMAGEN = [];

  ELEMENTOS_AUDIO = [];
  ELEMENTOS_AUDIO_RESPUESTA = [];

  displayBoxHtmlText = 1;

  PATH_ROOT = URL_SERVICIOS + 'upload/';

  SETTINGS_MANAGE_CARD = {
    viewExecution1: 'add',
    viewExecution2: 'add',
  };

  theme = {
    name: 'light',
    background: 'bg-light',
    background2: 'bg-light',
    text: 'text-dark',
    btnType1: 'btn-primary',
  };

  DATA_UPDATE1 = {
    idElemento: 0,
    elementoTexto: '',
  };

  DATA_UPDATE2 = {
    idElemento: 0,
    elementoTexto: '',
  };

  viewExecution1 = 'add';
  viewExecution2 = 'add';

  nTime1 = 0;

  boxToggle = {
    question_text: false,
    question_sound: false,
    response_text: false,
    response_sound: false,
    images: false,
  }

  constructor(
    private loadingService: CargandoService,
    public userService: UsuarioService,
    public modalTarjetaPreguntaRespuestaService: ModalTarjetaPreguntaRespuestaService,
    private targetService: TarjetaService,
    private imageService: ImagesService,
    public skillService: HabilidadesService,
    public settingsService: SettingsService,
    private toastr: ToastrService
  ) {
    this.theme = this.settingsService.loadThemeFromStorage();
  }

  ngDoCheck() {
    if (this.modalTarjetaPreguntaRespuestaService.oculto === '' && this.nTime1 === 0) {
      this.cargarDatos();
      this.nTime1 = 1;
    }
  }

  cargarDatos() {
    this.loadingService.mostrarAnimacion();
    this.getTarjetaById()
      .then(() => {
        this.getElementosTextoByTarjetaId()
          .then(() => {
            this.displayDataHtml()
              .then(() => {
                this.getElementosImagenByUserId()
                  .then(() => {
                    this.getElementosAudioByTarjetaId()
                      .then(() => {
                        this.loadingService.ocultarAnimacion();
                      });
                  });
              });
          });
      });
  }

  cerrarModal() {
    this.nTime1 = 0;

    this.title = '';

    this.ELEMENTOS_TEXTO = [];
    this.ELEMENTOS_TEXTO_RESPUESTA = [];

    this.ELEMENTOS_IMAGEN = [];

    this.ELEMENTOS_AUDIO = [];
    this.ELEMENTOS_AUDIO_RESPUESTA = [];

    this.displayBoxHtmlText = 1;

    this.SETTINGS_MANAGE_CARD = {
      viewExecution1: 'add',
      viewExecution2: 'add',
    };

    this.DATA_UPDATE1 = {
      idElemento: 0,
      elementoTexto: '',
    };

    this.DATA_UPDATE2 = {
      idElemento: 0,
      elementoTexto: '',
    };

    this.viewExecution1 = 'add';
    this.viewExecution2 = 'add';

    this.targetService.__refreshTarjetasByHabilidadId();
    this.modalTarjetaPreguntaRespuestaService.ocultarModal();
  }

  getUrlImage(url) {
    this.urlImgRequested = url;
  }

  updateTargetsTitleById() {
    const dataEnviar = {
      token: this.userService.token,
      TarjetaID: this.modalTarjetaPreguntaRespuestaService.TarjetaID,
      title: this.title,
    };

    this.btnUpdateTitle.cargando = 1;

    this.targetService
      .updateTargetsTitleById(dataEnviar)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.toastr.success('', 'Título actualizado', { timeOut: 5000 });
        } else {
          this.toastr.error('Error', 'Ocurrió un error!', { timeOut: 5000 });
        }
        setTimeout(() => {
          this.btnUpdateTitle.cargando = 0;
        }, 3000);
      });
  }

  updateElementoTexto(e) {
    const dataEnviar = {
      token: this.userService.token,
      idElemento: e.idElemento,
      elementoTexto: e.text,
    };

    this.targetService
      .actualizarElementoTexto(dataEnviar)
      .subscribe((resp2: any) => {
        this.clearText();
        this.cargarDatos();
        if (resp2.ok) {
          this.toastr.success('', 'Texto actualizado', { timeOut: 5000 });
        } else {
          this.toastr.error('Error', 'Ocurrió un error!', { timeOut: 5000 });
        }
      });
  }

  actualizarElementoTexto(e, id) {
    this.displayBoxHtmlText = 0;
    this.DATA_UPDATE1.elementoTexto = null;
    this.DATA_UPDATE1.idElemento = null;
    this.SETTINGS_MANAGE_CARD.viewExecution1 = null;

    this.DATA_UPDATE2.elementoTexto = null;
    this.DATA_UPDATE2.idElemento = null;
    this.SETTINGS_MANAGE_CARD.viewExecution2 = null;
    setTimeout(() => {
      if (id === 1) {
        this.DATA_UPDATE1.idElemento = e.ET_id;
        this.DATA_UPDATE1.elementoTexto = e.ET_texto;

        this.SETTINGS_MANAGE_CARD.viewExecution1 = 'update';
      } else if (id === 2) {
        this.DATA_UPDATE2.idElemento = e.ET_id;
        this.DATA_UPDATE2.elementoTexto = e.ET_texto;

        this.SETTINGS_MANAGE_CARD.viewExecution2 = 'update';
      }
      this.displayBoxHtmlText = 1;
    }, 450);
  }

  clearText(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.DATA_UPDATE1.elementoTexto = null;
      this.DATA_UPDATE1.idElemento = null;
      this.SETTINGS_MANAGE_CARD.viewExecution1 = null;

      this.DATA_UPDATE2.elementoTexto = null;
      this.DATA_UPDATE2.idElemento = null;
      this.SETTINGS_MANAGE_CARD.viewExecution2 = null;
      this.displayBoxHtmlText = 0;
      setTimeout(() => {
        this.DATA_UPDATE1.elementoTexto = '';
        this.DATA_UPDATE1.idElemento = 0;
        this.SETTINGS_MANAGE_CARD.viewExecution1 = 'add';

        this.DATA_UPDATE2.elementoTexto = '';
        this.DATA_UPDATE2.idElemento = 0;
        this.SETTINGS_MANAGE_CARD.viewExecution2 = 'add';
        this.displayBoxHtmlText = 1;
        resolve(true);
      }, 450);
    });
  }

  agregarElementoTexto(modo) {
    const dataEnviar = {
      token: this.userService.token,
      TarjetaID: this.modalTarjetaPreguntaRespuestaService.TarjetaID,
      texto: modo.text,
      modo: modo.type,
    };

    if (modo.text.length > 1499) {
      this.toastr.error(
        'El texto ha sobrepasado la cantidad de carácteres, por favor reducir el contenido.',
        'Ocurrió un error!',
        { timeOut: 5000 }
      );
      return;
    }

    this.targetService
      .agregarElementoTexto(dataEnviar)
      .subscribe((resp2: any) => {
        this.clearText();
        if (resp2.ok) {
          this.toastr.success('', 'Texto agregado', { timeOut: 5000 });
        } else {
          this.toastr.error('', 'Ocurrió un error!', { timeOut: 5000 });
        }
        this.cargarDatos();
      });
  }

  getElementosTextoByTarjetaId(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dataEnviar = {
        token: this.userService.token,
        TarjetaID: this.modalTarjetaPreguntaRespuestaService.TarjetaID,
      };

      this.ELEMENTOS_TEXTO_RESPUESTA = [];
      this.ELEMENTOS_TEXTO = [];

      this.targetService
        .getElementosTextoByTarjetaId(dataEnviar)
        .subscribe((respuesta: any) => {
          const resp2 = respuesta.data;

          if (respuesta.ok) {
            resp2.map((dataMap) => {
              if (dataMap.ET_modo === 'RESPUESTA') {
                this.ELEMENTOS_TEXTO_RESPUESTA.push(dataMap);
              }
              if (dataMap.ET_modo === 'PREGUNTA') {
                this.ELEMENTOS_TEXTO.push(dataMap);
              }
            });

            setTimeout(() => {
              resolve(true);
            }, 500);
            return;
          }
          resolve(true);
        });
    });
  }

  getTarjetaById(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dataEnviar = {
        token: this.userService.token,
        tarjetaId: this.modalTarjetaPreguntaRespuestaService.TarjetaID,
      };

      this.targetService
        .getTarjetaById(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.title = respuesta.dataStoreProcedure.title;
            resolve(true);
            return;
          }
          resolve(true);
        });
    });
  }

  displayDataHtml(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let cont1 = 0;
      let cont2 = 0;
      this.ELEMENTOS_TEXTO.map((dataMap) => {
        const boxPregunta = (
          document.getElementById('boxPregunta_' + cont1)
        ) as HTMLInputElement;
        boxPregunta.innerHTML = dataMap.ET_texto;
        cont1++;
      });

      this.ELEMENTOS_TEXTO_RESPUESTA.map((dataMap) => {
        const boxRespuesta = (
          document.getElementById('boxRespuesta_' + cont2)
        ) as HTMLInputElement;
        boxRespuesta.innerHTML = dataMap.ET_texto;
        cont2++;
      });

      resolve(true);
    });
  }

  eliminarElementoTexto(id) {
    const dataEnviar = {
      token: this.userService.token,
      elementoTextoID: id,
    };
    this.clearText();
    this.targetService
      .eliminarElementoTexto(dataEnviar)
      .subscribe((data: any) => {
        if (data.ok) {
          this.toastr.success('', 'Se eliminó correctamente', {
            timeOut: 5000,
          });
        }
        this.cargarDatos();
      });
  }

  getElementosImagenByUserId(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dataEnviar = {
        token: this.userService.token,
      };

      this.ELEMENTOS_IMAGEN = [];

      this.imageService
        .getByUserId(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            const resp2 = respuesta.data;
            this.ELEMENTOS_IMAGEN = resp2;
            resolve(true);
            return;
          }
          resolve(true);
        });
      resolve(true);
    });
  }

  onSelect(event) {
    const elemento = event.target;

    if (
      elemento.value.split('.')[1] !== 'png' &&
      elemento.value.split('.')[1] !== 'jpg' &&
      elemento.value.split('.')[1] !== 'jpeg' &&
      elemento.value.split('.')[1] !== 'JPG'
    ) {
      this.toastr.error(
        'Solo puedes subir imagenes de tipo png, jpg and jpeg',
        'Error!',
        { timeOut: 5000 }
      );
      ((
        document.getElementById('RESP_file-upload_IMAGEN')
      ) as HTMLInputElement).value = '';

      return;
    }

    if (elemento.files.length > 0) {
      if (elemento.files[0].size / 1000 > 450) {
        this.toastr.error(
          'Solo se permite imagenes con tamaño menor a 450 Kilobytes',
          'Error!',
          { timeOut: 5000 }
        );
        ((
          document.getElementById('RESP_file-upload_IMAGEN')
        ) as HTMLInputElement).value = '';
        return;
      }

      const formData = new FormData();
      formData.append('token', this.userService.token);

      formData.append('file', elemento.files[0]);

      this.imageService.uploadImage(formData).subscribe((data: any) => {
        if (data.ok) {
          this.getElementosImagenByUserId();
        }
        ((
          document.getElementById('RESP_file-upload_IMAGEN')
        ) as HTMLInputElement).value = '';
        return;
      });
    }
  }

  removeImageById(data) {
    const dataEnviar = {
      idImagen: data.EI_id,
      nombreBackend: data.EI_nombreBackend,
      extension: data.EI_extension,
      token: this.userService.token,
    };

    this.imageService.removeImageById(dataEnviar).subscribe(() => {
      this.getElementosImagenByUserId();
    });
  }

  eliminarElementoAudio(data) {
    const dataEnviar = {
      idImagen: data.EA_id,
      nombreBackend: data.EA_nombreBackend,
      extension: data.EA_extension,
      token: this.userService.token,
    };

    this.targetService
      .eliminarElementoAudio(dataEnviar)
      .subscribe(() => {
        this.getElementosAudioByTarjetaId();
      });
  }

  onSelectAudio(event, modo) {
    const elemento = event.target;

    if (elemento.value.split('.')[1] !== 'mp3') {
      this.toastr.error('Solo puedes subir imagenes de tipo mp3', 'Error!', {
        timeOut: 5000,
      });

      ((
        document.getElementById('RESP_file-upload_AUDIO')
      ) as HTMLInputElement).value = '';
      ((
        document.getElementById('PRE_file-upload_AUDIO')
      ) as HTMLInputElement).value = '';

      return;
    }

    if (elemento.files.length > 0) {
      if (elemento.files[0].size / 1000 > 1024) {
        this.toastr.error(
          'Solo se permite imagenes con tamaño menor a 1024 Kilobytes',
          'Error!',
          { timeOut: 5000 }
        );
        ((
          document.getElementById('RESP_file-upload_IMAGEN')
        ) as HTMLInputElement).value = '';
        return;
      }

      const formData = new FormData();
      formData.append('token', this.userService.token);
      formData.append('modo', modo);
      formData.append(
        'TarjetaID',
        '' + this.modalTarjetaPreguntaRespuestaService.TarjetaID
      );

      formData.append('file', elemento.files[0]);

      this.targetService
        .subirElementoAudio(formData)
        .subscribe((data: any) => {
          if (data.ok) {
            this.getElementosAudioByTarjetaId();
          }
          ((
            document.getElementById('RESP_file-upload_AUDIO')
          ) as HTMLInputElement).value = '';
          ((
            document.getElementById('PRE_file-upload_AUDIO')
          ) as HTMLInputElement).value = '';
          return;
        });
    }
  }

  getElementosAudioByTarjetaId(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dataEnviar = {
        token: this.userService.token,
        TarjetaID: this.modalTarjetaPreguntaRespuestaService.TarjetaID,
      };

      this.ELEMENTOS_AUDIO_RESPUESTA = [];
      this.ELEMENTOS_AUDIO = [];

      this.targetService
        .getElementosAudioByTarjetaId(dataEnviar)
        .subscribe((respuesta: any) => {
          const resp2 = respuesta.data;

          if (respuesta.ok) {
            resp2.map((num) => {
              if (num.EA_modo === 'RESPUESTA') {
                this.ELEMENTOS_AUDIO_RESPUESTA.push(num);
              }
              if (num.EA_modo === 'PREGUNTA') {
                this.ELEMENTOS_AUDIO.push(num);
              }
            });

            resolve(true);
            return;
          }
          resolve(true);
        });
    });
  }
}
