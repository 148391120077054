import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablaComponent } from './tabla/tabla.component';
import { ManageCardComponent } from './manage-card/manage-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataHabilidadComponent } from './data-habilidad/data-habilidad.component';
import { BoxTextHtmlComponent } from './box-text-html/box-text-html.component';
import { NgxEditorModule } from 'ngx-editor';
import { GraphicBarChartComponent } from './graphic-bar-chart/graphic-bar-chart.component';

import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { TargetComponent } from './target/target.component';

@NgModule({
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting] }, // add as factory to your providers
  ],
  declarations: [
    TablaComponent,
    ManageCardComponent,
    DataHabilidadComponent,
    BoxTextHtmlComponent,
    GraphicBarChartComponent,
    TargetComponent,
  ],
  exports: [
    TablaComponent,
    ManageCardComponent,
    DataHabilidadComponent,
    ReactiveFormsModule,
    BoxTextHtmlComponent,
    GraphicBarChartComponent,
    TargetComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxEditorModule,
    ChartModule,
  ],
})
export class VistasModuleModule {}
