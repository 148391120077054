<nav class="navbar navbar-expand-lg bg-faded header-navbar header_custom {{theme.background}}">
  <div class="container-fluid">
    <div class="navbar-header">
      <button type="button" data-toggle="collapse" class="navbar-toggle d-lg-none float-left {{theme.text}}">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <span class="d-lg-none navbar-right navbar-collapse-toggle">
        <a (click)="toggleOption1()" class="open-navbar-container {{theme.text}}">
          <i class="ft-more-vertical"></i>
        </a>
        <div *ngIf="displayMenu1===1" class="dropdown-menu text-left d-block dropdown-menu-right {{theme.background}}">
          <a [routerLink]="['/perfil']" (click)="displayMenu1=0" class="d-block text-center py-1 {{theme.text}}">
            <i class="ft-edit mr-2"></i>
            <span>Ver perfil</span>
          </a>
          <div class="dropdown-divider"></div>
          <a (click)="userService.logout()" class="d-block text-center {{theme.text}}">
            <i class="ft-power mr-2"></i>
            <span>Salir</span>
          </a>
        </div>
      </span>
    </div>
    <div class="navbar-container">
      <div id="navbarSupportedContent" class="collapse navbar-collapse">
        <ul class="navbar-nav">
          <li class="nav-item mr-2 d-none d-lg-block">
            <a id="navbar-fullscreen" href="javascript:;" class="nav-link apptogglefullscreen">
              <i class="ft-maximize font-medium-3 {{theme.text}}"></i>
              <p class="d-none">fullscreen</p>
            </a>
          </li>

          <li class="dropdown nav-item">
            <a id="header_btn_1" (click)="toggle1()" data-toggle="dropdown"
              class="nav-link position-relative dropdown-toggle">
              <i id="header_icon_1" class="ft-user font-medium-3 {{theme.text}}"></i>
              <p class="d-none">User Settings</p>
            </a>
            <div id="header_boxItems1"
              class="dropdown-menu text-left dropdown-menu-right {{theme.background}} {{display1}}">

              <a [routerLink]="['/perfil']" class="ml-3 py-1 {{theme.text}}">
                <i class="ft-edit mr-2"></i>
                <span>Ver perfil</span>
              </a>
              <div class="dropdown-divider"></div>
              <a (click)="userService.logout()" class="ml-3 {{theme.text}}">
                <i class="ft-power mr-2"></i>
                <span>Salir</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>