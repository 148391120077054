import { Component, DoCheck } from '@angular/core';
import {
  ImagesService,
  SettingsService,
  TarjetaService,
  UsuarioService,
} from 'src/app/services/service.index';
import { CargandoService } from '../cargando/cargando.service';
import { ModalTarjetaMemoriaService } from './modal-tarjeta-memoria.service';

import { URL_SERVICIOS } from 'src/app/config/config';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-tarjeta-memoria',
  templateUrl: './modal-tarjeta-memoria.component.html',
})
export class ModalTarjetaMemoriaComponent implements DoCheck {
  title = '';

  btnUpdateTitle = {
    cargando: 0,
  };

  ELEMENTOS_TEXTO = [];
  ELEMENTOS_IMAGEN = [];
  ELEMENTOS_AUDIO = [];

  PATH_ROOT = URL_SERVICIOS + 'upload/';

  SETTINGS_MANAGE_CARD = {
    viewExecution: 'add',
  };

  displayBoxHtmlText = 1;

  urlImgRequested = '';

  nTime1 = 0;

  DATA_UPDATE = {
    idElemento: 0,
    elementoTexto: '',
  };

  theme = {
    name: 'light',
    background: 'bg-light',
    background2: 'bg-light',
    text: 'text-dark',
    btnType1: 'btn-primary',
  };

  boxToggle = {
    text: false,
    sound: false,
    images: false,
  }

  constructor(
    private loadingService: CargandoService,
    public userService: UsuarioService,
    public modalTarjetaMemoriaService: ModalTarjetaMemoriaService,
    private targetService: TarjetaService,
    private imageService: ImagesService,
    public settingsService: SettingsService,
    private toastr: ToastrService
  ) {
    this.theme = this.settingsService.loadThemeFromStorage();
  }

  ngDoCheck() {
    if (this.modalTarjetaMemoriaService.oculto === '' && this.nTime1 === 0) {
      this.cargarDatos();
      this.nTime1 = 1;
    }
  }

  cerrarModal() {
    this.nTime1 = 0;
    this.SETTINGS_MANAGE_CARD = {
      viewExecution: 'add',
    };

    this.DATA_UPDATE = {
      idElemento: 0,
      elementoTexto: '',
    };

    this.title = '';

    this.ELEMENTOS_TEXTO = [];
    this.ELEMENTOS_IMAGEN = [];
    this.ELEMENTOS_AUDIO = [];

    this.targetService.__refreshTarjetasByHabilidadId();
    this.modalTarjetaMemoriaService.ocultarModal();
  }

  cargarDatos() {
    this.loadingService.mostrarAnimacion();
    this.getElementosTextoByTarjetaId()
      .then(() => {
        this.getTarjetaById()
          .then(() => {
            this.displayDataHtml()
              .then(() => {
                this.getElementosImagenByUserId()
                  .then(() => {
                    this.getElementosAudioByTarjetaId()
                      .then(() => {
                        this.loadingService.ocultarAnimacion();
                      });
                  });
              });
          });
      });
  }

  displayDataHtml(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let cont1 = 0;
      this.ELEMENTOS_TEXTO.map((dataMap) => {
        const boxPregunta = (
          document.getElementById('boxPregunta_' + cont1)
        ) as HTMLInputElement;
        boxPregunta.innerHTML = dataMap.ET_texto;
        cont1++;
      });
      resolve(true);
    });
  }

  actualizarElementoTexto(e) {
    this.displayBoxHtmlText = 0;
    this.DATA_UPDATE.elementoTexto = null;
    this.DATA_UPDATE.idElemento = null;
    this.SETTINGS_MANAGE_CARD.viewExecution = null;
    setTimeout(() => {
      this.DATA_UPDATE.idElemento = e.ET_id;
      this.DATA_UPDATE.elementoTexto = e.ET_texto;

      this.SETTINGS_MANAGE_CARD.viewExecution = 'update';
      this.displayBoxHtmlText = 1;
    }, 450);
  }

  updateElementoTexto(e) {
    const dataEnviar = {
      token: this.userService.token,
      idElemento: e.idElemento,
      elementoTexto: e.text,
    };

    this.targetService
      .actualizarElementoTexto(dataEnviar)
      .subscribe((resp2: any) => {
        this.clear().then(() => {
          this.cargarDatos();
        });
        if (resp2.ok) {
          this.toastr.success('', 'Elemento texto actualizado', {
            timeOut: 4500,
          });
        } else {
          this.toastr.error(resp2.msn, 'Ocurrió un error!', { timeOut: 4500 });
        }
      });
  }

  eliminarElementoTexto(id) {
    const dataEnviar = {
      token: this.userService.token,
      elementoTextoID: id,
    };

    this.targetService
      .eliminarElementoTexto(dataEnviar)
      .subscribe((data: any) => {
        this.clear().then(() => {
          this.cargarDatos();
        });
      });
  }

  clear(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.DATA_UPDATE.elementoTexto = null;
      this.DATA_UPDATE.idElemento = null;
      this.SETTINGS_MANAGE_CARD.viewExecution = null;
      this.ELEMENTOS_TEXTO = null;
      this.ELEMENTOS_IMAGEN = null;
      this.ELEMENTOS_AUDIO = null;
      this.displayBoxHtmlText = 0;

      setTimeout(() => {
        this.DATA_UPDATE.elementoTexto = '';
        this.DATA_UPDATE.idElemento = 0;
        this.SETTINGS_MANAGE_CARD.viewExecution = 'add';
        this.ELEMENTOS_TEXTO = [];
        this.ELEMENTOS_IMAGEN = [];
        this.ELEMENTOS_AUDIO = [];
        this.displayBoxHtmlText = 1;
        resolve(true);
      }, 450);
    });
  }

  clear2(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.SETTINGS_MANAGE_CARD.viewExecution = 'update';
      setTimeout(() => {
        this.SETTINGS_MANAGE_CARD.viewExecution = 'add';
        resolve(true);
      }, 450);
    });
  }

  getUrlImage(url) {
    this.urlImgRequested = url;
  }

  getTarjetaById(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dataEnviar = {
        token: this.userService.token,
        tarjetaId: this.modalTarjetaMemoriaService.TarjetaID,
      };

      this.targetService
        .getTarjetaById(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.title = respuesta.dataStoreProcedure.title;
            resolve(true);
            return;
          }
          resolve(true);
        });
    });
  }

  updateTargetsTitleById() {
    const dataEnviar = {
      token: this.userService.token,
      TarjetaID: this.modalTarjetaMemoriaService.TarjetaID,
      title: this.title,
    };

    this.btnUpdateTitle.cargando = 1;

    this.targetService
      .updateTargetsTitleById(dataEnviar)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.toastr.success('', 'Título actualizado', { timeOut: 5000 });
        } else {
          this.toastr.error('Error', 'Ocurrió un error!', { timeOut: 5000 });
        }
        setTimeout(() => {
          this.btnUpdateTitle.cargando = 0;
        }, 3000);
      });
  }

  agregarElementoTexto(modo) {
    const dataEnviar = {
      token: this.userService.token,
      TarjetaID: this.modalTarjetaMemoriaService.TarjetaID,
      texto: modo.text,
      modo: modo.type,
    };

    this.targetService
      .agregarElementoTexto(dataEnviar)
      .subscribe((resp2: any) => {
        this.clear().then(() => {
          this.cargarDatos();
        });
        if (resp2.ok) {
          this.toastr.success('', 'Elemento texto agregado', { timeOut: 4500 });
        } else {
          this.toastr.error(resp2.msn, 'Ocurrió un error!', { timeOut: 4500 });
        }
      });
  }

  getElementosTextoByTarjetaId(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dataEnviar = {
        token: this.userService.token,
        TarjetaID: this.modalTarjetaMemoriaService.TarjetaID,
      };

      this.ELEMENTOS_TEXTO = [];

      this.targetService
        .getElementosTextoByTarjetaId(dataEnviar)
        .subscribe((respuesta: any) => {
          const resp2 = respuesta.data;
          if (respuesta.ok) {
            resp2.map((num) => {
              if (num.ET_modo === 'MEMORIA') {
                this.ELEMENTOS_TEXTO.push(num);
              }
            });

            setTimeout(() => {
              resolve(true);
            }, 500);
            return;
          }
          resolve(true);
        });
    });
  }

  getElementosImagenByUserId(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dataEnviar = {
        token: this.userService.token,
      };

      this.ELEMENTOS_IMAGEN = [];

      this.imageService
        .getByUserId(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            const resp2 = respuesta.data;
            this.ELEMENTOS_IMAGEN = resp2;
            resolve(true);
            return;
          }
          resolve(true);
        });
    });
  }

  onSelect(event) {
    const elemento = event.target;

    if (
      elemento.value.split('.')[1] !== 'png' &&
      elemento.value.split('.')[1] !== 'jpg' &&
      elemento.value.split('.')[1] !== 'jpeg' &&
      elemento.value.split('.')[1] !== 'JPG'
    ) {
      this.toastr.error(
        'Remember that you can only upload images of type png, jpg and jpeg',
        'Ocurrió un error!',
        { timeOut: 4500 }
      );

      ((
        document.getElementById('PRE_file-upload_IMAGEN')
      ) as HTMLInputElement).value = '';

      return;
    }

    if (elemento.files.length > 0) {
      if (elemento.files[0].size / 1000 > 450) {
        this.toastr.error(
          'Solo se permite imagenes con tamaño menor a 450 Kilobytes',
          'Ocurrió un error!',
          { timeOut: 4500 }
        );

        ((
          document.getElementById('PRE_file-upload_IMAGEN')
        ) as HTMLInputElement).value = '';
        return;
      }

      const formData = new FormData();
      formData.append('token', this.userService.token);

      formData.append('file', elemento.files[0]);

      this.imageService.uploadImage(formData).subscribe((data: any) => {
        if (data.ok) {
          this.getElementosImagenByUserId();
        }
        ((
          document.getElementById('PRE_file-upload_IMAGEN')
        ) as HTMLInputElement).value = '';
        return;
      });
    }
  }

  removeImageById(data) {
    const dataEnviar = {
      idImagen: data.EI_id,
      nombreBackend: data.EI_nombreBackend,
      extension: data.EI_extension,
      token: this.userService.token,
    };

    this.imageService.removeImageById(dataEnviar).subscribe(() => {
      this.getElementosImagenByUserId();
    });
  }

  getElementosAudioByTarjetaId(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dataEnviar = {
        token: this.userService.token,
        TarjetaID: this.modalTarjetaMemoriaService.TarjetaID,
      };

      this.ELEMENTOS_AUDIO = [];

      this.targetService
        .getElementosAudioByTarjetaId(dataEnviar)
        .subscribe((respuesta: any) => {
          const resp2 = respuesta.data;

          if (respuesta.ok) {
            resp2.map((num) => {
              if (num.EA_modo === 'MEMORIA') {
                this.ELEMENTOS_AUDIO.push(num);
              }
            });

            resolve(true);
            return;
          }
          resolve(true);
        });
    });
  }

  onSelectAudio(event, modo) {
    const elemento = event.target;

    if (elemento.value.split('.')[1] !== 'mp3') {
      this.toastr.error(
        'Remember that you can only upload images of type mp3',
        'Ocurrió un error!',
        { timeOut: 4500 }
      );

      ((
        document.getElementById('PRE_file-upload_AUDIO')
      ) as HTMLInputElement).value = '';

      return;
    }

    if (elemento.files.length > 0) {
      if (elemento.files[0].size / 1000 > 1024) {
        this.toastr.error(
          'Solo se permite imagenes con tamaño menor a 1024 Kilobytes',
          'Ocurrió un error!',
          { timeOut: 4500 }
        );

        ((
          document.getElementById('PRE_file-upload_IMAGEN')
        ) as HTMLInputElement).value = '';
        return;
      }

      const formData = new FormData();
      formData.append('token', this.userService.token);
      formData.append('modo', modo);
      formData.append(
        'TarjetaID',
        '' + this.modalTarjetaMemoriaService.TarjetaID
      );

      formData.append('file', elemento.files[0]);

      this.targetService
        .subirElementoAudio(formData)
        .subscribe((data: any) => {
          if (data.ok) {
            this.getElementosAudioByTarjetaId();
          }
          ((
            document.getElementById('PRE_file-upload_AUDIO')
          ) as HTMLInputElement).value = '';
          return;
        });
    }
  }

  eliminarElementoAudio(data) {
    const dataEnviar = {
      idImagen: data.EA_id,
      nombreBackend: data.EA_nombreBackend,
      extension: data.EA_extension,
      token: this.userService.token,
    };

    this.targetService
      .eliminarElementoAudio(dataEnviar)
      .subscribe(() => {
        this.getElementosAudioByTarjetaId();
      });
  }
}
