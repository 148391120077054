import { Component, DoCheck } from '@angular/core';
import {
  EntrenamientoService,
  HabilidadesService,
  SettingsService,
  UsuarioService,
} from 'src/app/services/service.index';
import { CargandoService } from '../cargando/cargando.service';
import { ModalCrearEntrenamientoService } from './modal-crear-entrenamiento.service';

@Component({
  selector: 'app-modal-crear-entrenamiento',
  templateUrl: './modal-crear-entrenamiento.component.html',
})
export class ModalCrearEntrenamientoComponent implements DoCheck {
  nTime1 = 0;

  HABILIDADES_DATA = [];

  ENTRENAMIENTO_DATA = {
    idHabilidad: 0,
    nivelConocimiento: 3,
  };

  theme = {
    name: 'light',
    background: 'bg-light',
    background2: 'bg-light',
    text: 'text-dark',
    btnType1: 'btn-primary',
  };

  pasoActual = 1;
  pasoFin = 1;

  constructor(
    public modalCrearEntrenamientoService: ModalCrearEntrenamientoService,
    public userService: UsuarioService,
    public entrenamientoService: EntrenamientoService,
    public loadingService: CargandoService,
    private skillService: HabilidadesService,
    public settingsService: SettingsService
  ) {
    this.setTheme();
  }

  ngDoCheck() {
    if (this.modalCrearEntrenamientoService.oculto === '' && this.nTime1 === 0) {
      this.cargarDatos();
      this.nTime1 = 1;
    }
  }

  setTheme() {
    this.theme = this.settingsService.loadThemeFromStorage();
  }

  cargarDatos() {
    this.loadingService.mostrarAnimacion();
    this.obtenerHabilidadByUsuarioId()
      .then(() => {
        this.loadingService.ocultarAnimacion();
      });
  }

  crear() {
    const dataToSend = {
      token: this.userService.token,
      idHabilidad: this.ENTRENAMIENTO_DATA.idHabilidad,
      nivelConocimiento: this.ENTRENAMIENTO_DATA.nivelConocimiento,
      status: 1,
      proprietary: 1,
    };

    this.entrenamientoService.agregarEntrenamiento(dataToSend)
      .subscribe((respuesta: any) => {
        if (respuesta.ok) {
          this.entrenamientoService.__refreshEntrenamientos();
          this.cerrarModal();
          return;
        }
      });
  }

  obtenerHabilidadByUsuarioId(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dataEnviar = {
        token: this.userService.token,
      };

      this.skillService
        .obtenerHabilidadByUsuarioId(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok && respuesta.data.length > 0) {
            this.HABILIDADES_DATA = respuesta.data;
            this.ENTRENAMIENTO_DATA.idHabilidad =
              this.HABILIDADES_DATA[0].HA_id;

            resolve(true);
            return;
          }
          resolve(true);
        });
    });
  }

  cerrarModal() {
    this.nTime1 = 0;
    this.pasoActual = 1;

    // this.ProductSet=null;

    this.modalCrearEntrenamientoService.ocultarModal();
  }
}
