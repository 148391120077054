import { Component, DoCheck, ViewChild, ElementRef } from '@angular/core';
import { HabilidadModel } from 'src/app/models/habilidad.model';
import {
  HabilidadesService,
  SettingsService,
  UsuarioService,
} from 'src/app/services/service.index';
import { ModalHabilidadService } from './modal-habilidad.service';

import { ToastrService } from 'ngx-toastr';
import { CargandoService } from '../cargando/cargando.service';

@Component({
  selector: 'app-modal-habilidad',
  templateUrl: './modal-habilidad.component.html',
  styleUrls: ['./modal-habilidad.component.css'],
})
export class ModalHabilidadComponent implements DoCheck {
  @ViewChild('EditorHtmlBox') EditorHtmlBox;

  nTime1 = 0;
  requestDataBoxTextHtml = 0;

  optionsFormatoHabilidad = [{}];

  HABILIDAD_DATA_ERROR = {
    nombre: '',
    richDetail: '',
  };

  theme = {
    name: 'light',
    background: 'bg-light',
    background2: 'bg-light',
    text: 'text-dark',
    btnType1: 'btn-primary',
  };

  HABILIDAD_DATA = new HabilidadModel('', 1);

  constructor(
    public skillService: HabilidadesService,
    public modalSkillService: ModalHabilidadService,
    public userService: UsuarioService,
    public loadingService: CargandoService,
    public settingsService: SettingsService,
    private toastr: ToastrService
  ) {
    this.theme = this.settingsService.loadThemeFromStorage();
  }

  ngDoCheck() {
    if (this.modalSkillService.oculto === '' && this.nTime1 === 0) {
      setTimeout(() => {
        this.HABILIDAD_DATA.nombre =
          this.modalSkillService.nombreHabilidad;
      }, 500);

      this.cargarDatos();
      this.nTime1 = 1;
    }
  }

  cargarDatos() {
    this.loadingService.mostrarAnimacion();
    this.obtenerOptionsFormatoEntrenamiento()
      .then(() => {
        this.loadingService.ocultarAnimacion();
      });
  }
  getDataBoxTextHtml(e) {
    setTimeout(() => {
      this.requestDataBoxTextHtml = 0;
      this.HABILIDAD_DATA.richDetail = e;
      this.updateHabilidad(1);
    }, 500);
  }

  updateHabilidad(ready = 0) {
    if (ready === 0) {
      if (
        this.modalSkillService.idHabilidad === 0 ||
        !this.modalSkillService.idHabilidad
      ) {
        return;
      }
      this.requestDataBoxTextHtml = 1;
      return;
    }

    if (!this.validarCampo('nombre') || !this.validarCampo('richDetail')) {
      return;
    }

    this.HABILIDAD_DATA.token = this.userService.token;
    this.HABILIDAD_DATA.id = this.modalSkillService.idHabilidad;
    this.HABILIDAD_DATA.state = this.modalSkillService.state;

    this.skillService
      .updateHabilidad(this.HABILIDAD_DATA)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.toastr.success('', 'Habilidad actualizada', { timeOut: 4500 });
          this.skillService.__refreshHabilidades();
          this.cerrarModal();
        } else {
          this.toastr.error(resp2.msn, 'Ocurrió un error!', { timeOut: 4500 });
        }
      });
  }

  agregarHabilidad() {
    if (!this.validarCampo('nombre')) {
      return;
    }

    this.HABILIDAD_DATA.token = this.userService.token;

    this.skillService
      .agregarHabilidad(this.HABILIDAD_DATA)
      .subscribe((resp2: any) => {
        if (resp2.ok) {
          this.toastr.success('', 'Habilidad Creada', { timeOut: 4500 });
          this.skillService.__refreshHabilidades();
          this.cerrarModal();
        } else {
          this.toastr.error(resp2.msn, 'Ocurrió un error!', { timeOut: 4500 });
        }
      });
  }

  obtenerOptionsFormatoEntrenamiento(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dataEnviar = {
        token: this.userService.token,
      };

      this.skillService
        .obtenerOptionsFormatoEntrenamiento(dataEnviar)
        .subscribe((respuesta: any) => {
          if (respuesta.ok) {
            this.optionsFormatoHabilidad = respuesta.data;
            resolve(true);
            return;
          }
          this.optionsFormatoHabilidad = [];
          resolve(true);
        });
    });
  }

  cerrarModal() {
    this.nTime1 = 0;

    this.HABILIDAD_DATA = new HabilidadModel('', 1);

    this.optionsFormatoHabilidad = [{}];

    this.modalSkillService.ocultarModal();
  }

  validarCampo(campo) {
    if (campo === 'nombre') {
      if (this.HABILIDAD_DATA.nombre === '') {
        this.HABILIDAD_DATA_ERROR.nombre = 'Este campo no puede estar vacio';
        return false;
      } else if (this.HABILIDAD_DATA.nombre.length > 249) {
        this.HABILIDAD_DATA_ERROR.nombre =
          'Este campo no puede tener mas de 249 caracteres';
        return false;
      } else {
        this.limpiar();
        return true;
      }
    }

    if (campo === 'richDetail') {
      if (this.HABILIDAD_DATA.richDetail.length > 699) {
        this.HABILIDAD_DATA_ERROR.richDetail =
          'Este campo no puede tener mas de 700 caracteres';
        return false;
      } else {
        this.limpiar();
        return true;
      }
    }
  }

  limpiar() {
    this.HABILIDAD_DATA_ERROR = {
      nombre: '',
      richDetail: '',
    };
  }
}
