import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalEntrenamientoTarjetaMemoriaService {
  public oculto = 'oculto';
  public idEntrenamiento = 0;

  ocultarModal() {
    this.oculto = 'oculto';
    this.idEntrenamiento = 0;
  }

  mostrarModal(idEntrenamiento) {
    this.oculto = '';
    this.idEntrenamiento = idEntrenamiento;
  }
}
