import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ModalHabilidadComponent } from './modal-habilidad/modal-habilidad.component';
import { VistasModuleModule } from '../vistas/vistas-module.module';
import { PruebaComponent } from './prueba/prueba.component';

@NgModule({
  declarations: [ModalHabilidadComponent, PruebaComponent],
  exports: [ModalHabilidadComponent],
  imports: [CommonModule, FormsModule, VistasModuleModule],
})
export class ComponentesModule {}
