<div class="app-sidebar {{theme.background}}">
    <div class="sidebar-header">
        <div class="logo clearfix {{theme.background}}">
            <a class="logo-text float-left">
                <span class="{{theme.text}} font-weight-bolder" style="font-size: 25px;">STONEKNOW</span>
            </a>
            <a (click)="toggleActiveSidebar()" class="nav-toggle d-none d-sm-none d-md-none d-lg-block {{theme.text}}">
                <i class="fa fa-bars" aria-hidden="true"></i>
            </a>
            <a (click)="toggleActiveSidebar()" class="nav-close d-block d-md-block d-lg-none d-xl-none {{theme.text}}">
                <i class="ft-x"></i>
            </a>
        </div>
    </div>

    <div class="sidebar-content {{theme.background}}">
        <div class="nav-container">
            <ul id="main-menu-navigation" data-menu="menu-navigation" data-scroll-to-active="true"
                class="navigation navigation-main">
                <li routerLinkActive="active">
                    <a [routerLink]="['/dashboard']" class="{{theme.text}}">
                        <i class="ft-layout"></i>
                        <span data-i18n="" class="menu-title">Dashboard</span>
                    </a>
                </li>

                <li routerLinkActive="active">
                    <a [routerLink]="['/store']" class="{{theme.text}}">
                        <i class="ft-shopping-cart"></i>
                        <span data-i18n="" class="menu-title">Tienda</span>
                    </a>
                </li>

                <li routerLinkActive="active">
                    <a [routerLink]="['/entrenamiento']" class="{{theme.text}}">
                        <i class="ft-activity"></i>
                        <span data-i18n="" class="menu-title">Entrenamiento</span>
                    </a>
                </li>

                <li routerLinkActive="active">
                    <a [routerLink]="['/habilidades']" class="{{theme.text}}">
                        <i class="ft-codepen"></i>
                        <span data-i18n="" class="menu-title">Habilidades</span>
                    </a>
                </li>

                <!-- <li routerLinkActive="active">
                    <a [routerLink]="['/joinMe']" class="{{theme.text}}">
                        <i class="ft-heart"></i>
                        <span data-i18n="" class="menu-title">Unirme</span>
                    </a>
                </li> -->

                <li *ngIf="ROL==='ADMINISTRADOR'" routerLinkActive="active">
                    <a [routerLink]="['/usuarios']" class="{{theme.text}}">
                        <i class="ft-users"></i>
                        <span data-i18n="" class="menu-title">Usuarios</span>
                    </a>
                </li>

                <li routerLinkActive="active">
                    <a [routerLink]="['/guides']" class="{{theme.text}}">
                        <i class="ft-book"></i>
                        <span data-i18n="" class="menu-title">Guía</span>
                    </a>
                </li>

                <li routerLinkActive="active">
                    <a [routerLink]="['/settings']" class="{{theme.text}}">
                        <i class="ft-settings"></i>
                        <span data-i18n="" class="menu-title">Configuración</span>
                    </a>
                </li>


            </ul>
        </div>
    </div>

    <div class="sidebar-background"></div>

</div>