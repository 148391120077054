import { Component } from '@angular/core';

import { UsuarioService } from '../../services/service.index';

import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-recuperar-clave',
  templateUrl: './recuperar-clave.component.html',
})
export class RecuperarClaveComponent {
  email = '';

  vista = 'requestRecovery';

  FORM_REGISTRO = {
    token: '',
    password: '',
  };

  FORM_REGISTRO_ERROR = {
    token: '',
    password: '',
  };

  DATA_ERROR = {
    email: '',
  };

  constructor(
    public router: Router,
    public userService: UsuarioService,
    private toastr: ToastrService
  ) { }

  sentTokenValidationToEmail() {
    if (!this.validarCampo('email')) {
      return;
    }

    const dataEnviar = {
      email: this.email,
    };

    this.userService
      .sendTokenRecoveryPassToEmail(dataEnviar)
      .subscribe((response) => {
        if (response.ok) {
          this.toastr.success(
            '',
            'Se ha enviado el token de validación a tu e-mail',
            { timeOut: 5000 }
          );
          this.vista = 'changePassword';
          return;
        } else {
          this.toastr.error(response.mensaje, 'Ocurrió un error!', {
            timeOut: 5000,
          });
        }
      });
  }

  recuperar() {
    if (!this.validarCampo('token') || !this.validarCampo('password')) {
      return;
    }

    this.userService
      .changePasswordFromRecovery(this.FORM_REGISTRO)
      .subscribe((response) => {
        if (response.ok) {
          this.toastr.success('', 'Se ha actualizado la contraseña', {
            timeOut: 4000,
          });

          this.router.navigate(['/login']);
          // this.vista='changePassword';
          return;
        } else {
          this.toastr.error(response.mensaje, 'Ocurrió un error!', {
            timeOut: 5000,
          });
        }
      });
  }

  validarCampo(campo) {
    if (campo === 'email') {
      if (this.email === '') {
        this.DATA_ERROR.email = 'Este campo no puede estar vacio';
        return false;
      } else if (this.email.length > 249) {
        this.DATA_ERROR.email =
          'Este campo no puede tener mas de 249 caracteres';
        return false;
      } else {
        this.limpiar();
        return true;
      }
    }

    if (campo === 'password') {
      if (this.FORM_REGISTRO.password === '') {
        this.FORM_REGISTRO_ERROR.password = 'Este campo no puede estar vacio';
        return false;
      } else if (this.FORM_REGISTRO.password.length > 219) {
        this.FORM_REGISTRO_ERROR.password =
          'Este campo no puede tener mas de 219 caracteres';
        return false;
      } else {
        this.limpiar();
        return true;
      }
    }

    if (campo === 'token') {
      if (this.FORM_REGISTRO.token === '') {
        this.FORM_REGISTRO_ERROR.token = 'Este campo no puede estar vacio';
        return false;
      } else {
        this.limpiar();
        return true;
      }
    }
  }

  limpiar() {
    this.DATA_ERROR = {
      email: '',
    };

    this.FORM_REGISTRO_ERROR = {
      token: '',
      password: '',
    };
  }
}
