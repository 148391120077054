/*global setTimeout, document*/
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  DoCheck,
  OnDestroy,
} from '@angular/core';

import { toHTML, Editor, Toolbar, toDoc } from 'ngx-editor';

@Component({
  selector: 'app-box-text-html',
  templateUrl: './box-text-html.component.html',
  styles: [],
})
export class BoxTextHtmlComponent implements OnInit, DoCheck, OnDestroy {
  @Output() emitOnChange = new EventEmitter<any>();
  @Input() requestData = 0;
  @Input() mydataTextHtml = '';
  @Input() mode = 'all';

  nTime1 = 0;
  nTime2 = 0;

  editor: Editor;

  richDetailHtmlString;
  html: Record<string, any>;

  // colorPresets = ['red', '#FF0000', 'rgb(255, 0, 0)'];

  toolbar: Toolbar = [
    // default value
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  changeData() {
    if (this.html) {
      this.richDetailHtmlString = toHTML(this.html);
    } else {
      this.richDetailHtmlString = '';
    }

    this.emitOnChange.emit(this.richDetailHtmlString);
  }

  ngDoCheck() {
    if (this.requestData === 1 && this.nTime1 === 0) {
      this.changeData();
      this.nTime1 = 1;
    }

    if (this.mydataTextHtml !== '' && this.nTime2 === 0) {
      this.html = toDoc(this.mydataTextHtml);
      this.nTime2 = 1;
    }

    if (this.requestData === 0 && this.nTime1 === 1) {
      this.nTime1 = 0;
    }
  }

  ngOnInit(): void {
    this.editor = new Editor();

    setTimeout(() => {
      const ngxeditorMenuBar = document.getElementsByClassName('ngxeditorMenuBar');
      const ngxeditor = document.getElementsByClassName('ngxeditor');
      const ngxeditorMenuItem = document.getElementsByClassName('ngxeditorMenuItem');
      const ngxeditorSeparator = document.getElementsByClassName('ngxeditorSeparator');
      const ngxeditorDropdown = document.getElementsByClassName('ngxeditorDropdown');

      Array.from(ngxeditorMenuBar).forEach((el: HTMLElement) => {
        el.style.display = 'block';
        el.style.textAlign = 'left';
      });

      Array.from(ngxeditor).forEach((el: HTMLElement) => {
        el.style.textAlign = 'left';
      });

      Array.from(ngxeditorMenuItem).forEach((el: HTMLElement) => {
        el.style.display = 'inline-block';
      });

      Array.from(ngxeditorSeparator).forEach((el: HTMLElement) => {
        el.style.display = 'none';
      });

      Array.from(ngxeditorDropdown).forEach((el: HTMLElement) => {
        el.style.display = 'inline-block';
        el.style.top = '-3px';
      });
    }, 500);
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
}
