import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, FormControl, Validators } from '@angular/forms';

import swal from 'sweetalert2';

import { UsuarioService } from '../services/service.index';
import { Usuario } from '../models/usuario.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  FORM_REGISTRO = {
    email: '',
    password: '',
    password2: '',
  };

  FORM_REGISTRO_ERROR = {
    email: '',
    password: '',
    password2: '',
  };

  forma: UntypedFormGroup;

  constructor(
    public userService: UsuarioService,
    public router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
  }

  limpiar() {
    this.FORM_REGISTRO_ERROR = {
      email: '',
      password: '',
      password2: '',
    };
  }

  registrarUsuario() {
    // if(!this.forma.value.condiciones)
    // {
    //   swal.fire("Importante", "Debe de aceptar las condiciones", "error");

    //   return;
    // }

    if (!this.validarCampo('email') || !this.validarCampo('password')) {
      return;
    }

    const dataToSend = {
      email: this.FORM_REGISTRO.email,
      password: this.FORM_REGISTRO.password,
      ipClient: 0,
    };

    this.userService.getIP().subscribe((respuesta: any) => {
      dataToSend.ipClient = respuesta.ip;

      this.userService
        .agregarUsuarioFromRegistro(dataToSend)
        .subscribe((resp: any) => {
          if (resp.ok) {
            this.toastr.success('Usuario Creado', 'Éxito!', { timeOut: 5000 });
            this.router.navigate(['/login']);
          } else {
            this.toastr.error(resp.msn, 'Ocurrió un error!', { timeOut: 5000 });
          }
        });
    });
  }

  validarCampo(campo) {
    if (campo === 'email') {
      if (this.FORM_REGISTRO.email === '') {
        this.FORM_REGISTRO_ERROR.email = 'Este campo no puede estar vacio';
        return false;
      } else if (this.FORM_REGISTRO.email.length > 69) {
        this.FORM_REGISTRO_ERROR.email =
          'Este campo no puede tener mas de 69 caracteres';
        return false;
      } else {
        this.limpiar();
        return true;
      }
    }

    if (campo === 'password') {
      if (this.FORM_REGISTRO.password === '') {
        this.FORM_REGISTRO_ERROR.password = 'Este campo no puede estar vacio';
        return false;
      } else if (this.FORM_REGISTRO.password.length > 219) {
        this.FORM_REGISTRO_ERROR.password =
          'Este campo no puede tener mas de 219 caracteres';
        return false;
      } else if (this.FORM_REGISTRO.password2 === '') {
        this.FORM_REGISTRO_ERROR.password2 = 'Este campo no puede estar vacio';
        return false;
      } else if (this.FORM_REGISTRO.password2.length > 219) {
        this.FORM_REGISTRO_ERROR.password2 =
          'Este campo no puede tener mas de 219 caracteres';
        return false;
      } else if (this.FORM_REGISTRO.password !== this.FORM_REGISTRO.password2) {
        this.FORM_REGISTRO_ERROR.password = 'La contraseña debe conicidir para validar';
        this.FORM_REGISTRO_ERROR.password2 = 'La contraseña debe conicidir para validar';
        return false;
      } else {
        this.limpiar();
        return true;
      }
    }
  }
}
