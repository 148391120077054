import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-manage-card',
  templateUrl: './manage-card.component.html',
})
export class ManageCardComponent {
  requestDataBoxTextHtml = 0;
  richDetail = '';

  @Input() viewExecution = '';
  @Input() text = '';
  @Input() idElemento = 0;

  @Input() type = '';

  @Input() btnAdd = 1;

  @Output() emitAdd = new EventEmitter<any>();
  @Output() emitUpdate = new EventEmitter<any>();
  @Output() emitCancelUpdate = new EventEmitter<any>();

  constructor(private toastr: ToastrService) { }

  getDataBoxTextHtml(e) {
    setTimeout(() => {
      this.requestDataBoxTextHtml = 0;
      this.richDetail = e;
      // this.emitirUpdate(1)
    }, 500);
  }

  emitirAdd() {
    this.requestDataBoxTextHtml = 1;

    setTimeout(() => {
      if (this.richDetail === '') {
        this.toastr.warning(
          'Tienes que agregar contenido en la caja de texto.',
          'Advertencia!',
          { timeOut: 5000 }
        );
        return;
      }

      const dataToSend = {
        text: this.richDetail,
        type: this.type,
      };

      this.emitAdd.emit(dataToSend);
    }, 1500);
  }

  emitirUpdate() {
    this.requestDataBoxTextHtml = 1;

    setTimeout(() => {
      const dataToSend = {
        text: this.richDetail,
        idElemento: this.idElemento,
      };
      this.emitUpdate.emit(dataToSend);
    }, 1500);
  }

  emitirCancelUpdate() {
    this.emitCancelUpdate.emit();
  }

  checkInput(data) {
    if (data.type === 'number') {
      if (!this['' + data.label]) {
        this['' + data.label] = 0;
      }

      if (this['' + data.label] < data.min) {
        this['' + data.label] = data.min;
      }

      if (this['' + data.label] > data.max) {
        this['' + data.label] = data.max;
      }
    }
  }
}
