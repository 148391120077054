<div class="row">
    <div class="col-md-10">
        <h5 class="text-left text-bold-500">Texto</h5>
        <app-box-text-html [requestData]="requestDataBoxTextHtml" [mydataTextHtml]="text"
            (emitOnChange)="getDataBoxTextHtml($event)">
        </app-box-text-html>
    </div>

    <div class="col-md-2">
        <h5 class="text-left text-bold-500"> &nbsp;&nbsp;</h5>
        <button *ngIf="viewExecution==='add' && btnAdd===1" (click)="emitirAdd()"
            class="btn btn-success">Agregar</button>

        <button *ngIf="viewExecution==='update'" [disabled]="text === ''" (click)="emitirUpdate()"
            class="btn btn-success">
            Actualizar
        </button>
        <button *ngIf="viewExecution==='update'" (click)="emitirCancelUpdate()" class="btn btn-danger">Cancelar</button>
    </div>
</div>