import { Component } from '@angular/core';
import { CargandoService } from './cargando.service';

@Component({
  selector: 'app-cargando',
  templateUrl: './cargando.component.html',
})
export class CargandoComponent {
  constructor(public loadingService: CargandoService) {}
}
