<div class="table-responsive box-shadow-1 mt-3 mb-3 {{theme.background}} {{theme.text}} p-2">
   <h2 *ngIf="TITLE!==''" class="text-center font-weight-bolder">{{TITLE}}</h2>

   <table class="table table-hover text-center m-0">
      <thead>
         <tr>
            <th class="{{theme.text}}" *ngFor="let h of HEADERS; index as i">{{h}}</th>
         </tr>
      </thead>
      <tbody class="{{theme.text}}">
         <ng-container>
            <ng-container *ngFor="let d of DATA; index as i">
               <tr>
                  <td>{{ i+init+(pagination.actual-1)*12 }}</td>
                  <td class="text-left" *ngFor="let x of HEADERS2">{{ d[x] }}</td>

                  <td class="text-truncate">
                     <a *ngIf="EVENTS.search===1" (click)="emitirSearch(d)" class="primary p-0 m-1">
                        <i class="fa fa-search font-medium-3 mr-2"></i>
                     </a>
                     <a *ngIf="EVENTS.update===1" (click)="emitirUpdate(d)" class="success p-0 m-1">
                        <i class="ft-edit-2 font-medium-3 mr-2"></i>
                     </a>
                     <a *ngIf="EVENTS.delete===1" (click)="emitirDelete(d[itemIdentificador])" class="danger p-0 m-1">
                        <i class="ft-trash font-medium-3"></i>
                     </a>
                  </td>
               </tr>
            </ng-container>
         </ng-container>

      </tbody>
   </table>

   <nav class="text-center mb-3">
      <ng-container *ngIf="pagination.anterior === true; then loggedIn; else loggedOut">
      </ng-container>
      <ng-template #loggedIn>
         <a class="page-link d-inline-block" style="background: unset;border: unset;"
            (click)="cambiar_Pagina(pagination.actual - 1)">
            <i class="ft-chevrons-left display-4"></i>
         </a>
      </ng-template>
      <ng-template #loggedOut>
         <a class="page-link d-inline-block" style="background: unset;border: unset;">
            <i class="ft-chevrons-left display-4 {{theme.text}}"></i>
         </a>
      </ng-template>


      <ng-container *ngIf="pagination.siguiente === true; then loggedIn2; else loggedOut2">
      </ng-container>
      <ng-template #loggedIn2>
         <a class="page-link d-inline-block" style="background: unset;border: unset;"
            (click)="cambiar_Pagina(pagination.actual + 1)">
            <i class="ft-chevrons-right display-4"></i>
         </a>
      </ng-template>
      <ng-template #loggedOut2>
         <a class="page-link d-inline-block" style="background: unset;border: unset;">
            <i class="ft-chevrons-right display-4 {{theme.text}}"></i>
         </a>
      </ng-template>

   </nav>

</div>