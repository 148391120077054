<div class="fondo-negro animated fadeIn" [ngClass]="modalSkillService.oculto">

  <div class="modal" style="display:block;" tabindex="-1" role="dialog">

    <div *ngIf="modalSkillService.idHabilidad===0" class="modal-dialog" role="document">
      <div class="modal-content {{theme.background2}} {{theme.text}}" style="overflow: auto;height: 555px;">
        <div class="modal-header">
          <h2 class="modal-title">Agregar Habilidad</h2>

          <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" align="center">

          <div class="card-body">

            <div class="form-group row">
              <label class="col-md-3 label-control font-medium-3">Nombre</label>
              <div class="col-md-9">
                <input [(ngModel)]="HABILIDAD_DATA.nombre" autocomplete="none" type="text"
                  class="form-control {{theme.background}} {{theme.text}}" name="nombre">
                <p class="text-danger">{{HABILIDAD_DATA_ERROR.nombre}}</p>
              </div>
            </div>


            <div class="form-group row">
              <label class="col-md-3 label-control font-medium-3">Formato entrenamiento</label>
              <div class="col-md-9">
                <select [(ngModel)]="HABILIDAD_DATA.formatoEntrenamiento" name="users-list-role"
                  class="form-control {{theme.background}} {{theme.text}}">
                  <option *ngFor="let formatoHabilidad of optionsFormatoHabilidad" value="{{formatoHabilidad.id}}">
                    {{formatoHabilidad.nombre}}</option>
                </select>
              </div>
            </div>

          </div>


        </div>
        <div class="modal-footer">
          <button (click)="cerrarModal()" type="button" class="btn btn-danger btn-lg"
            style="padding-top: 2px !important;">cerrar</button>

          <button (click)="agregarHabilidad()" type="button" class="btn btn-primary btn-lg"
            style="padding-top: 2px !important;">Agregar</button>
        </div>
      </div>
    </div>


    <div *ngIf="modalSkillService.idHabilidad>0" class="modal-dialog modal-lg" role="document">
      <div class="modal-content {{theme.background2}} {{theme.text}}" style="overflow: auto;height: 555px;">
        <div class="modal-header">
          <h2 class="modal-title">Modificar Habilidad</h2>

          <button (click)="cerrarModal()" type="button" class="close text-danger font-large-2" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" align="center">

          <div class="card-body">

            <div class="form-group row">
              <label class="col-md-3 label-control font-medium-3">Nombre</label>
              <div class="col-md-9">
                <input [(ngModel)]="HABILIDAD_DATA.nombre" autocomplete="none" type="text"
                  class="form-control {{theme.background}} {{theme.text}}" name="nombre">
                <p class="text-danger">{{HABILIDAD_DATA_ERROR.nombre}}</p>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-3 label-control font-medium-3">Detalle Enriquecido</label>
              <div class="col-md-9">

                <app-box-text-html [requestData]="requestDataBoxTextHtml"
                  [mydataTextHtml]="modalSkillService.richDetail" (emitOnChange)="getDataBoxTextHtml($event)">
                </app-box-text-html>
                <p class="text-danger">{{HABILIDAD_DATA_ERROR.richDetail}}</p>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-3 label-control font-medium-3">Estado</label>
              <div class="col-md-9">
                <p class="text-danger text-left">Si el estado es público significa que se mostrará en la tienda para que
                  cualquier
                  otro usuario pueda utilizarlo.</p>
                <select [(ngModel)]="modalSkillService.state" name="state"
                  class="form-control {{theme.background}} {{theme.text}}">
                  <option value="0">Privado</option>
                  <option value="1">Público</option>
                </select>
              </div>
            </div>

          </div>

        </div>
        <div class=" modal-footer">
          <button (click)="cerrarModal()" type="button" class="btn btn-danger btn-lg"
            style="padding-top: 2px !important;">cerrar</button>

          <button (click)="updateHabilidad()" type="button" class="btn btn-primary btn-lg"
            style="padding-top: 2px !important;">Actualizar</button>
        </div>
      </div>
    </div>


  </div>
</div>