<div class="wrapper">
  <div class="main-panel">
    <!-- BEGIN : Main Content-->
    <div class="main-content" style="padding:0px;">
      <div class="content-wrapper" style="padding:0px;">
        <!--Forgot Password Starts-->
        <section style="background-color: #EDA591;">
          <div class="container-fluid forgot-password-bg">
            <div class="row full-height-vh m-0 d-flex align-items-center justify-content-center">
              <div class="col-md-7 col-sm-12">
                <div class="card">
                  <div class="card-content">
                    <div class="card-body fg-image p-0" style="background: #1C2140;">
                      <div class="row m-0">
                        <div class="col-lg-6 d-none d-lg-block text-center py-2">
                          <img src="assets/img/gallery/forgot.png" alt="" class="img-fluid" width="300" height="230">
                        </div>

                        <div *ngIf="vista==='requestRecovery'" class="col-lg-6 col-md-12 bg-white px-4 pt-3">
                          <h4 class="mb-2 card-title">Recuperar contraseña</h4>
                          <p class="card-text mb-3">
                            Ingresa el correo de la cuenta que quieres recuperar tu contraseña.
                          </p>
                          <label><input type="checkbox" (click)="vista='changePassword'"> Ya tengo un token</label>

                          <input type="text" [(ngModel)]="email" class="form-control mb-3" placeholder="Email"
                            [className]="DATA_ERROR.email !== '' ? 'form-control border-danger' : 'form-control'">
                          <p class="text-danger">{{DATA_ERROR.email}}</p>

                          <div class="fg-actions d-flex justify-content-between">
                            <div class="login-btn">
                              <button routerLink="/login" class="btn btn-outline-primary btn-lg">
                                <a class="text-decoration-none">Regresar al login</a>
                              </button>
                            </div>
                            <div class="recover-pass">
                              <button (click)="sentTokenValidationToEmail()" class="btn btn-primary btn-lg">
                                Recuperar
                              </button>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="vista==='changePassword'" class="col-lg-6 col-md-12 bg-white px-4 pt-3">
                          <h4 class="mb-2 card-title">Recuperar contraseña</h4>
                          <p class="card-text mb-3">
                            Por favor ingresa el token enviado a tu cuenta e-mail.
                          </p>
                          <label><input type="checkbox" (click)="vista='requestRecovery'"> Obtener un token</label>
                          <p class="mb-0">Token</p>
                          <input autocomplete="off" [(ngModel)]="FORM_REGISTRO.token" name="token" type="varchar"
                            class="form-control mb-3" placeholder="Token"
                            [className]="FORM_REGISTRO_ERROR.token !== '' ? 'form-control border-danger' : 'form-control'" />
                          <p class="text-danger">{{FORM_REGISTRO_ERROR.token}}</p>

                          <p class="mb-0">Nueva clave</p>
                          <input autocomplete="off" [(ngModel)]="FORM_REGISTRO.password" name="password" type="password"
                            class="form-control mb-3" placeholder="Contraseña nueva"
                            [className]="FORM_REGISTRO_ERROR.password !== '' ? 'form-control border-danger' : 'form-control'" />
                          <p class="text-danger">{{FORM_REGISTRO_ERROR.password}}</p>

                          <div class="fg-actions d-flex justify-content-between">
                            <div class="login-btn">
                              <button routerLink="/login" class="btn btn-outline-primary btn-lg">
                                <a class="text-decoration-none">Regresar al login</a>
                              </button>
                            </div>
                            <div class="recover-pass">
                              <button (click)="recuperar()" class="btn btn-primary btn-lg">
                                Renovar contraseña
                              </button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--Forgot Password Ends-->

      </div>
    </div>
    <!-- END : End Main Content-->
  </div>
</div>