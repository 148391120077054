import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalTarjetaMemoriaService {
  public oculto = 'oculto';
  public TarjetaID = 0;
  public onlySee = 0;

  ocultarModal() {
    this.oculto = 'oculto';
    this.TarjetaID = 0;
    this.onlySee = 0;
  }
  mostrarModal(TarjetaID, onlySee = 0) {
    this.TarjetaID = TarjetaID;
    this.onlySee = onlySee;
    this.oculto = '';
  }
}
